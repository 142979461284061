import * as React from 'react';
import {LoadingButton} from "@mui/lab";
import {Button} from "@mui/material";

export function ButtonSubmit({
                                 text,
                                 color,
                                 sx,
                                 isFetching,
                                 size = 'medium',
                                 variant = "contained",
                                 fullWidth = true,
                                 startIcon
                             }) {
    return (
        <LoadingButton sx={sx} fullWidth={fullWidth} color={color} type={"submit"} size={size} variant={variant}
                       disableElevation loadingPosition="start" loading={isFetching} startIcon={startIcon}>
            <span> {text}</span>
        </LoadingButton>
    );
}

export function ButtonReset({
                                text,
                                color,
                                sx,
                                size = 'medium',
                                variant = "contained",
                                startIcon,
                                handler
                            }) {
    return (
        <Button sx={sx} fullWidth={false} color={color} size={size} variant={variant}
                disableElevation startIcon={startIcon} onClick={handler}>
            <span> {text}</span>
        </Button>
    );
}

export function ButtonWithoutIcon({
                                      text,
                                      color,
                                      sx,
                                      size = 'medium',
                                      variant = "contained",
                                      handler,
                                      elevation = true
                                  }) {
    return (
        <Button sx={sx}
                fullWidth={false}
                color={color}
                size={size}
                variant={variant}
                disableElevation={!elevation}
                onClick={handler}>
            <span> {text}</span>
        </Button>
    );
}

export function ButtonWithSpinner({
                                      text,
                                      color,
                                      sx,
                                      isFetching,
                                      size = 'medium',
                                      variant = "contained",
                                      elevation = true,
                                      startIcon,
                                      handler,
                                      disabled = false
                                  }) {
    return (
        <LoadingButton sx={sx}
                       fullWidth={false}
                       color={color}
                       size={size}
                       variant={variant}
                       disableElevation={!elevation}
                       onClick={handler}
                       loadingPosition="start"
                       loading={isFetching}
                       startIcon={startIcon}
                       disabled={disabled}>
            <span> {text}</span>
        </LoadingButton>
    );
}

export function ButtonSubmitEndIcon({
                                        text,
                                        color,
                                        sx,
                                        isFetching,
                                        size = 'medium',
                                        variant = "contained",
                                        fullWidth = true,
                                        icon
                                    }) {
    return (
        <LoadingButton
            sx={sx}
            fullWidth={fullWidth}
            color={color}
            type={"submit"}
            size={size}
            variant={variant}
            disableElevation
            loadingPosition="end"
            loading={isFetching}
            endIcon={icon}>
            <span> {text}</span>
        </LoadingButton>
    );
}

export function ButtonLink({
                               text,
                               color,
                               size = 'medium',
                               variant = "contained",
                               fullWidth = true,
                               elevation = false,
                               startIcon,
                               link,
                               sx={}
                           }) {
    return (
        <Button
            sx={sx}
            fullWidth={fullWidth}
            color={color}
            size={size}
            variant={variant}
            disableElevation={!elevation}
            startIcon={startIcon}
            href={link}
        >
            {text}
        </Button>
    );
}
