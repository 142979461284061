import React from 'react';
import {Routes, Route, BrowserRouter} from "react-router-dom";

//Layout
import Layout from "../layouts/index";
import NonAuthLayout from "../layouts/nonAuthLayout";
import LayoutWitBack from "../layouts/layoutWithBack";
//routes
import {publicRoutes, authenticatedRoutes, authentificatedRoutesBack} from "./allRoutes";

const Index = () => {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Routes>
                    {authenticatedRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <Layout>
                                    {route.component}
                                </Layout>
                            }
                            key={idx}
                            exact={true}
                        />
                    ))}
                    {publicRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <NonAuthLayout>
                                    {route.component}
                                </NonAuthLayout>
                            }
                            key={idx}
                            exact={true}
                        />
                    ))}
                    {authentificatedRoutesBack.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <LayoutWitBack>
                                    {route.component}
                                </LayoutWitBack>
                            }
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Routes>
            </BrowserRouter>
        </React.Fragment>
    );
};

export default Index;