import {useTranslation} from "react-i18next";
import List from "@mui/material/List";
import React, {useEffect} from "react";
import {Avatar, Divider, ListItemButton, Typography} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import {PeopleAltOutlined} from "@mui/icons-material";
import {useLocation} from "react-router-dom";
import config from "../../../config";
import defaultUser from "../../../assets/images/user-dummy-img.jpg";

export default function ListAvatar({items}) {
    const {t} = useTranslation();
    const location = useLocation();

    useEffect(() => {
        localStorage.setItem("backUrls", JSON.stringify([location.pathname + location.search]));
    }, []);

    return (
        <List sx={{width: '100%', height: "100%", overflow: "auto"}} disablePadding>
            {items.map((item, index) => <React.Fragment key={index}>
                <ListItemButton
                    alignItems="center"
                    gap={1}
                    disableGutters={true}
                    disableTouchRipple={false}
                    sx={{paddingBottom: "2px", paddingTop: "2px"}}
                    href={'/admin/manager-doctors/' + item.id}
                >
                    <ListItemAvatar sx={{minWidth: 53}}>
                        <Avatar
                            alt="Remy Sharp"
                            className={"border-2 border-gray-200 dark:border-gray-600"}
                            sx={{height: 45, width: 45}}
                            src={item['image_path'] ? config.urlOrigin + item['image_path'] : defaultUser}/>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{flexGrow: 1}}
                        primary={item['full_name']}
                        secondary={item.phone}
                        className='text-custom-blue dark:text-gray-300'
                    />
                    <Typography variant={"body2"} sx={{textAlign: "end"}}>
                        <PeopleAltOutlined color={"secondary"}/> {item.doctorsNumber}
                    </Typography>
                </ListItemButton>
                <Divider/>
            </React.Fragment>)
            }
        </List>
    );
}

