import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, {useState} from 'react';
import {Box, useTheme} from '@mui/material';
import {TabContext, TabPanel} from "@mui/lab";
import SwipeableViews from 'react-swipeable-views';

const TabWithoutIcon = ({items}) => {
    const [value, setValue] = useState('0');

    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <Box sx={{width: '100%', minHeight: "80vh", typography: 'body1'}}>
            <TabContext value={value.toString()}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs
                        value={value.toString()}
                        onChange={handleChange}
                        aria-label="Services TabsStyled"
                        indicatorColor="primary"
                        textColor="inherit"
                        variant="fullWidth"
                        sx={{
                            "button": {
                                padding: 0,
                                minWidth: 30,
                                minHeight: 36,
                            },
                            minHeight: 36,
                            position: "fixed",
                            top: 50,
                            left: 0,
                            right: 0,
                            zIndex: 1000
                        }}
                        className={"bg-custom-blue text-gray-100 shadow-md"}
                    >
                        {items.map((item, index) => (
                            <Tab
                                key={index}
                                label={item.label}
                                value={index.toString()}
                            />
                        ))}
                    </Tabs>
                </Box>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={+value}
                    onChangeIndex={handleChangeIndex}
                >
                    {items.map((item, idx) => (
                        <TabPanel key={idx} value={value.toString()} index={idx.toString()} dir={theme.direction}
                                  sx={{minHeight: "50vh", padding: 0, paddingTop: "87px"}}>
                            {item.content}
                        </TabPanel>
                    ))}
                </SwipeableViews>
            </TabContext>
        </Box>
    );
}

export default TabWithoutIcon;