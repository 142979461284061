import React from 'react';

const NonAuthLayout = (props) => {

    return (
        <React.Fragment>
            <div className={""}>
                {props.children}
            </div>
        </React.Fragment>
    );
}

export default NonAuthLayout;