import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import config from "../../../config";
import {toast} from "react-toastify";
import {
    TextFieldOutlined,
    DateFieldDDMMYYYY,
    PhoneNumberFieldLabel
} from "../../../components/Fields/textFields";
import {AddSharp, DeleteOutlined, Person} from "@mui/icons-material";
import {RowRadioButtonsGroup} from "../../../components/Radios/radioGroups";
import {ButtonReset, ButtonSubmit} from "../../../components/buttons/buttons";
import {useTranslation} from "react-i18next";
import {Box, Paper} from "@mui/material";
import {useDispatch} from "react-redux";
import {addDoctorPatient} from "../../../store/common/action";

const AddNewPatients = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [isFetching, setIsFetching] = useState(false);

    const validation = useFormik({
        initialValues: {
            fullName: "",
            birthday: "",
            phoneNumber: "",
            gender: '1'
        },
        validationSchema: Yup.object().shape({
            fullName: Yup.string().required(t('required-field')),
            birthday: Yup.string().matches(/\d{2}\.\d{2}\.\d{4}/, t('date-invalid')),
            phoneNumber: Yup.string().required(t('required-field')).matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, t('phone-invalid')),
            gender: Yup.string().required(t('required-field'))
        }),
        onSubmit: values => {
            console.log("values", values);
            setIsFetching(true);
            fetch(config.urlOrigin + '/create-new-patient', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem('bonusOrgId'),
                },
                body: JSON.stringify({
                    ...values,
                    userId: +localStorage.getItem("bonusUserId")
                })
            }).then(async resp => {
                const jsonData = await resp.json();
                if (resp.status === 200) {
                    dispatch(addDoctorPatient(jsonData.data));

                    toast.success(t('patient-added'));

                } else {
                    console.log(jsonData.message);
                    toast.error(t('server-error'));
                }

            }).catch(error => {
                console.log(error);
                toast.error(t('connection-error'));

            }).finally(() => setIsFetching(false));
        }
    })

    return (
        <Box className="p-2">
            <Paper variant={"elevation"} className={"p-2"} sx={{backgroundColor: "background.paper"}}>
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <Box className={"mb-1.5"}>
                        <TextFieldOutlined
                            icon={<Person/>}
                            name={"fullName"}
                            label={t('client-name')}
                            error={validation.touched.fullName && validation.errors.fullName}
                            value={validation.values.fullName}
                            handler={validation.handleChange}
                        />
                    </Box>

                    <Box className={"mb-0.5"}>
                        <DateFieldDDMMYYYY
                            margin={"dense"}
                            name={"birthday"}
                            label={t('birthday')}
                            error={validation.touched.birthday && validation.errors.birthday}
                            value={validation.values.birthday}
                            handler={validation.handleChange}
                            size={'small'}
                        />
                    </Box>

                    <PhoneNumberFieldLabel
                        name={"phoneNumber"}
                        error={validation.touched.phoneNumber && validation.errors.phoneNumber}
                        value={validation.values.phoneNumber}
                        handler={validation.handleChange}
                        size={"small"}
                        label={t('phone-number')}
                    />

                    <RowRadioButtonsGroup
                        name={"gender"}
                        label={""}
                        items={[{label: t('male'), value: '1'}, {label: t('female'), value: '2'}]}
                        value={validation.values.gender}
                        handler={validation.handleChange}/>

                    <Box display={"flex"} gap={1} justifyContent={"end"}>
                        <ButtonReset
                            text={t('clear')}
                            sx={{mt: 2}}
                            color={'info'}
                            startIcon={<DeleteOutlined/>}
                            variant={"outlined"}
                            handler={() => {
                                validation.handleReset();
                            }}
                        />
                        <ButtonSubmit
                            text={t("add")}
                            sx={{mt: 2}}
                            color={'success'}
                            isFetching={isFetching}
                            startIcon={<AddSharp/>}
                            fullWidth={false}
                        />
                    </Box>
                </form>
            </Paper>
        </Box>
    );
}

export default AddNewPatients;