import React from "react";
import {Alert, Box, Paper, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ListAvatarDoctor from "./ListAvatarDoctor";
import {changeDoctorsConnA, changeDoctorsNoManager, changeManagers} from "../../../store/common/action";
import {ButtonWithSpinner} from "../../../components/buttons/buttons";
import Save3FillIcon from "remixicon-react/Save3FillIcon";
import {SelectOutlined} from "../../../components/Selects";
import Swal from "sweetalert2";
import config from "../../../config";
import {toast} from "react-toastify";

const AssignDoctors = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {doctorsNoManager, managers} = useSelector(state => state.Common);

    const [isFetching, setIsFetching] = React.useState(false);
    const [selectedManager, setSelectedManager] = React.useState('');

    const handleChange = (e, id) => {
        const newState = [...doctorsNoManager];
        const index = newState.findIndex((item) => item.id === id);
        newState[index].checked = e.target.checked;

        dispatch(changeDoctorsNoManager(newState));
    }

    const handleAssignDoctor = () => {
        const selectedDoctors = doctorsNoManager.reduce((accum, item) => {
            if (item.checked)
                accum.push(item.id);

            return accum;
        }, []);

        if (!selectedDoctors.length) {
            Swal.fire({
                icon: "info",
                text: "Please select at least one doctor!",
                showCloseButton: true,
                showConfirmButton: true
            });

        } else if (!selectedManager) {
            Swal.fire({
                icon: "info",
                text: "Please select a manager!",
                showCloseButton: true,
                showConfirmButton: true
            });

        } else {
            setIsFetching(true);
            fetch(config.urlOrigin + '/assign-doctors-to-manager', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem('bonusOrgId')
                },
                body: JSON.stringify({
                    doctors: selectedDoctors,
                    managerId: +selectedManager
                })
            }).then(async resp => {
                const jsonData = await resp.json();
                if (resp.status === 200) {
                    toast.success('dasdad');
                    const newState = [...doctorsNoManager].filter((item) => !selectedDoctors.includes(item.id));

                    dispatch(changeDoctorsConnA(newState));

                    const newManagers = [...managers];
                    const index = newState.findIndex((item) => item.id === +selectedManager);
                    newManagers[index].doctorsNumber += selectedDoctors.length;

                    dispatch(changeManagers(newManagers));

                } else {
                    console.log(jsonData.message);
                    toast.error(t('server-error'));
                }
            }).catch(err => {
                console.log(err);
                toast.error(t('connection-error'));

            }).finally(() => setIsFetching(false));
        }
    }

    const handleManagerChange = (e) => {
        setSelectedManager(e.target.value);
    }

    return (
        <Box className={"p-2"}>
            {
                doctorsNoManager.length > 0 ?
                    <React.Fragment>
                        <Paper className={"p-2 mb-2"}>
                            <Box className={"flex justify-between items-center gap-4"}>
                                <Box className={"grow"}>
                                    <SelectOutlined
                                        label={t('managers')}
                                        handler={handleManagerChange}
                                        value={selectedManager}
                                        fullWidth={true}
                                        size={"small"}
                                        items={managers.map(item => ({value: item.id, label: item.full_name}))}
                                    />
                                </Box>
                                <ButtonWithSpinner
                                    size={'medium'}
                                    color={"success"}
                                    variant={"contained"}
                                    handler={handleAssignDoctor}
                                    elevation={false}
                                    startIcon={<Save3FillIcon/>}
                                    isFetching={isFetching}
                                    text={t('save')}
                                />
                            </Box>
                        </Paper>
                        <Paper className={"p-2"}>
                            <Typography variant="h6" component="h6" fontSize={17} textAlign="center" marginBottom={1}>
                                {t('doctors-have-no-manager')}
                                <ListAvatarDoctor items={doctorsNoManager} handler={handleChange}/>
                            </Typography>
                        </Paper>
                    </React.Fragment> : <Alert severity={"info"} elevation={2}>{t('no-data-found')}</Alert>
            }
        </Box>
    );
}

export default AssignDoctors;