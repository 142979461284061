import React from "react";
import {Box} from "@mui/material";
import MapLocation from "./MapLocation";

const Index = () => {

    return (
        <Box sx={{pt: 7.5}} className={"p-2"}>
            <MapLocation/>
        </Box>
    );
}
export default Index;