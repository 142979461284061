import React from "react";
import {Alert, Box} from "@mui/material";
import {SharesCardActiveNoActions} from "../../../components/card/sharesCards";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const ActiveShares = () => {
    const {t} = useTranslation();

    const {sharesActive} = useSelector(state => state.Common);
    const {isLoading} = useSelector(state => state.LayoutReducer);

    return (
        <Box className="p-2" sx={{pt: 7.5}}>
            {
                !isLoading && (
                    sharesActive?.length > 0 ?
                        sharesActive.map((item, index) => (
                            <SharesCardActiveNoActions item={item}
                                                       key={index}/>
                        )) :
                        <Alert severity="info" elevation={2}>{t('no-data-found')}</Alert>
                )
            }
        </Box>
    );
}

export default ActiveShares;