import React from 'react';
import {Backdrop, Box, CircularProgress} from "@mui/material";
import Footer from "./Footer";
import HeaderWithBack from "./HeaderWithBack";
import {useSelector} from "react-redux";

const Index = (props) => {
    const {isLoading} = useSelector(state => state.LayoutReducer);

    return (
        <React.Fragment>
            <HeaderWithBack/>
            <Box className={"body-min-height"}>
                {props.children}
            </Box>
            <Footer/>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </React.Fragment>
    )
}

export default Index;