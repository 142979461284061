import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import config from "../../../config";
import {toast} from "react-toastify";
import ActiveBonuses from "./ActiveBonuses";
import {useDispatch} from "react-redux";
import {changeBonusesActive} from "../../../store/common/action";
import {changePageLoading} from "../../../store/layout/action";

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        fetch(config.urlOrigin + '/get-bonuses?active=1', {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                authorization: localStorage.getItem("bonusOrgId"),
            }
        }).then(async resp => {
            const jsonData = await resp.json();
            if (resp.status === 200) {
                dispatch(changeBonusesActive(jsonData.data));

            } else {
                toast.error(t('server-error'));
                console.log(jsonData.message);
            }

        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));

        }).finally(() => dispatch(changePageLoading(false)));

    }, []);

    return (
        <ActiveBonuses/>
    );
}

export default Index;