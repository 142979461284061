import {IS_LOADING, LANGUAGE, MODE} from "./actionType";

const INIT_STATE = {
    mode: localStorage.getItem('BONUS_MODE') || 'light',
    isLoading: true,
    language: localStorage.getItem('BONUS_LANG') || 'oz',
}

const LayoutReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MODE:
            return {
                ...state,
                mode: action.payload
            };
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case LANGUAGE:
            return {
                ...state,
                language: action.payload
            }

        default:
            return state;
    }
}

export default LayoutReducer;