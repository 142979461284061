import React, {useEffect, useState} from 'react';
import {Box, IconButton, Paper} from "@mui/material";
import {
    AccountBalanceSharp,
    BusinessCenterSharp,
    LoopOutlined,
    Person,
    PhotoCamera
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import config from "../../../config";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {PhoneNumberField, TextFieldOutlinedIcon} from "../../../components/Fields/textFields";
import {RowRadioButtonsGroup} from "../../../components/Radios/radioGroups";
import {ButtonReset, ButtonSubmit} from "../../../components/buttons/buttons";
import SaveFillIcon from "remixicon-react/SaveFillIcon";
import {changeAvatarLoading, setUserData} from "../../../store/common/action";
import {changePageLoading} from "../../../store/layout/action";
import AvatarLoading from "../../../components/AvatarLoading";

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {mode} = useSelector(state => state.LayoutReducer);
    const {user} = useSelector(state => state.Common);

    const [isFetching, setIsFetching] = useState(false);
    const [avatar, setAvatar] = useState({
        base64: "",
        type: ""
    }); // Initial avatar URL

    const updateUserData = () => {
        fetch(config.urlOrigin + '/get-user-data?id=' + localStorage.getItem('bonusUserId'), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem('bonusOrgId')
            }
        }).then(async resp => {
            const jsonData = await resp.json();

            if (resp.status === 200) {
                dispatch(setUserData(jsonData.data));
                await validation.setValues({
                    fullName: jsonData.data.full_name,
                    speciality: jsonData.data.speciality,
                    workplace: jsonData.data.workplace,
                    phoneNumber: jsonData.data.phone,
                    gender: String(jsonData.data.gender)
                });

                if (!jsonData.data.image_path) {
                    dispatch(changeAvatarLoading(false));
                }

            } else {
                console.log(jsonData.message);
                toast.error(t('server-error'));
            }
        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));

        }).finally(() => dispatch(changePageLoading(false)));
    }

    useEffect(() => {
        updateUserData();
    }, []);

    const handleUpload = (event) => {
        if (event.target.files && event.target.files[0]) {
            dispatch(changeAvatarLoading(true));

            const file = event.target.files[0];
            // console.log("file", file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar({
                    base64: reader.result,
                    type: file.type,
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const validation = useFormik({
        initialValues: {
            fullName: '',
            speciality: '',
            workplace: '',
            phoneNumber: '',
            gender: "1"
        },
        validationSchema: Yup.object().shape({
            fullName: Yup.string().required(t('required-field')),
            speciality: Yup.string().required(t('required-field')),
            workplace: Yup.string().required(t('required-field')),
            phoneNumber: Yup.string().required(t('required-field'))
                .matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, t('phone-invalid')),
            gender: Yup.string().required(t('required-field'))
        }),
        onSubmit: values => {
            // console.log("values", values);
            setIsFetching(true);
            fetch(config.urlOrigin + '/update-user', {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("bonusOrgId"),
                },
                body: JSON.stringify({
                    ...values,
                    image: avatar,
                    id: +localStorage.getItem('bonusUserId')
                })
            }).then(async resp => {
                const jsonData = await resp.json();
                if (resp.status === 200) {
                    toast.success(t('updated'));
                    dispatch(setUserData(jsonData.data));

                } else {
                    console.log(jsonData.message);
                    toast.error(t('server-error'));
                }
            })
                .catch(error => {
                    console.log(error);
                    toast.error(t('unknown-error'));
                })
                .finally(() => setIsFetching(false));
        }
    });

    return (
        <Box className="p-2" sx={{pt: 7.5}}>
            <Paper className={"p-2"}>
                <Box display="flex" justifyContent="center">
                    <Box position="relative" display="inline-block">
                        <AvatarLoading
                            src={avatar.base64 || (user.image_path ? (config.urlOrigin + user.image_path) : '')}
                        />
                        <IconButton
                            aria-label="upload picture"
                            component="label"
                            sx={{
                                position: 'absolute',
                                bottom: 4,
                                right: 1,
                                backgroundColor: mode === 'light' ? "#c5c5c5" : '#898b8f',
                                '&:hover': {
                                    backgroundColor: mode === 'light' ? "#c5c5c5" : '#898b8f',
                                },
                                '&:active': {
                                    backgroundColor: mode === 'light' ? "#c5c5c5" : '#898b8f',
                                }
                            }}
                        >
                            <input
                                type="file"
                                accept="image/*"
                                style={{display: 'none'}}
                                onChange={handleUpload}
                            />
                            <PhotoCamera/>
                        </IconButton>
                    </Box>
                </Box>

                <form className={"mt-1"} onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <TextFieldOutlinedIcon
                        icon={<Person/>}
                        name={"fullName"}
                        placeholder={t('enter-full-name')}
                        error={validation.touched.fullName && validation.errors.fullName}
                        value={validation.values.fullName}
                        handler={validation.handleChange}
                        size={'small'}
                    />
                    <TextFieldOutlinedIcon
                        icon={<BusinessCenterSharp/>}
                        name={"speciality"}
                        placeholder={t('enter-speciality')}
                        error={validation.touched.speciality && validation.errors.speciality}
                        value={validation.values.speciality}
                        handler={validation.handleChange}
                        size={'small'}
                    />

                    <TextFieldOutlinedIcon
                        icon={<AccountBalanceSharp/>}
                        name={"workplace"}
                        placeholder={t('enter-workplace')}
                        error={validation.touched.workplace && validation.errors.workplace}
                        value={validation.values.workplace}
                        handler={validation.handleChange}
                        size={'small'}
                    />

                    <PhoneNumberField
                        name={"phoneNumber"}
                        error={validation.touched.phoneNumber && validation.errors.phoneNumber}
                        value={validation.values.phoneNumber}
                        handler={validation.handleChange}
                        size={'small'}
                    />

                    <RowRadioButtonsGroup
                        name={"gender"}
                        label={""}
                        items={[{label: t('male'), value: '1'}, {label: t('female'), value: '2'}]}
                        value={validation.values.gender}
                        handler={validation.handleChange}/>

                    <Box display="flex" gap={1} justifyContent={"end"}>
                        <ButtonReset
                            text={t('update')}
                            sx={{mt: 1.5, textTransform: "capitalize"}}
                            color={'info'}
                            startIcon={<LoopOutlined/>}
                            size={"medium"}
                            variant={"outlined"}
                            handler={() => {
                                validation.handleReset();
                                updateUserData();
                            }}
                        />
                        <ButtonSubmit
                            text={t("save")}
                            sx={{mt: 1.5}}
                            size={"medium"}
                            color={'success'}
                            isFetching={isFetching}
                            startIcon={<SaveFillIcon/>}
                            fullWidth={false}/>
                    </Box>
                </form>
            </Paper>
        </Box>
    );
}

export default Index;