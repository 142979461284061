import * as React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {Box, Checkbox, Divider, ListItemButton,} from '@mui/material';
import {AccessTimeOutlined} from "@mui/icons-material";
import moment from "moment";

const ListWithCheckbox = ({items, handlerChange}) => {

    return (
        <List sx={{width: '100%', height: "100%", p: 0, pr: 1}}>
            {items.map((item, index) => <React.Fragment key={index}>
                <ListItemButton
                    alignItems="center" sx={{py: 0}}
                    disableTouchRipple={true}
                    disableGutters={true}
                >
                    <ListItemText
                        primary={
                            <Typography
                                variant={"subtitle1"}
                                component={"p"}
                                sx={{
                                    fontSize:15,
                                    lineHeight:1.3
                                }}
                            >
                                {item['full_name']}
                            </Typography>
                        }
                        className='text-custom-blue dark:text-gray-300'
                        secondary={
                            <Typography
                                sx={{display: 'inline'}}
                                component="span"
                                variant="body2"
                                color={'text.secondary'}
                            >
                                {item['phone'] || '-'}
                            </Typography>
                        }
                    />
                    <Box className={"flex flex-col items-end gap-1"}>
                        <Checkbox
                            sx={{
                                p: 0
                            }}
                            checked={item['checked'] === undefined ? false : item['checked']}
                            onChange={(event) => {
                                handlerChange(event, item.id);
                            }}
                            // size={"small"}
                            color={"info"}
                        />
                        <Typography
                            sx={{display: 'inline'}}
                            component="span"
                            variant="body2"
                            color={'text.secondary'}
                        >
                            <AccessTimeOutlined
                                sx={{
                                    fontSize: "14px",
                                    mt: "-3px"
                                }}/> {moment(item['created_time']).format('DD.MM.YYYY, HH:mm:ss')}
                        </Typography>
                    </Box>
                </ListItemButton>
                {index < items.length - 1 && <Divider/>}
            </React.Fragment>)
            }
        </List>
    );
}

export default ListWithCheckbox;
