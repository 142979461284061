import React from "react"
import {useTranslation} from "react-i18next";
import TabWithIcon from "../../../components/Tabs/TabWithIcon";
import {ArchiveTwoTone, TaskTwoTone} from "@mui/icons-material";
import Responses from "./Responses";
import {useDispatch} from "react-redux";
import config from "../../../config";
import {toast} from "react-toastify";
import {changeDemandResponses, changeDemandsToRespond} from "../../../store/common/action";
import Demands2Respond from "./Demands2Respond";
import {changePageLoading} from "../../../store/layout/action";

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    fetch(config.urlOrigin + '/get-user-demands', {
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            authorization: localStorage.getItem("bonusOrgId"),
        }
    }).then(async resp => {
        const jsonData = await resp.json();
        if (resp.status === 200) {
            const responded = [], notResponded = [];
            jsonData.data.forEach((item) => {
                if (item.status === 1)
                    notResponded.push(item);
                else
                    responded.push(item);
            });

            dispatch(changeDemandResponses(responded));
            dispatch(changeDemandsToRespond(notResponded));

        } else {
            toast.error(t('server-error'));
            console.log(jsonData.message);
        }

    }).catch(err => {
        console.log(err);
        toast.error(t('connection-error'));

    }).finally(() => dispatch(changePageLoading(false)));

    return (
        <TabWithIcon items={[
            {label: t("requests"), icon: <TaskTwoTone/>, content: <Demands2Respond/>},
            {label: t("archive"), icon: <ArchiveTwoTone/>, content: <Responses/>},
        ]}/>
    );
}

export default Index;