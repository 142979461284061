import React, {useEffect, useState} from 'react';
import {Box, IconButton, Paper, TextField} from "@mui/material";
import {
    LoopOutlined,
    MyLocationRounded,
    PhotoCamera
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {PhoneNumberField, TextFieldOutlined} from "../../components/Fields/textFields";
import {ButtonReset, ButtonSubmit} from "../../components/buttons/buttons";
import SaveFillIcon from "remixicon-react/SaveFillIcon";
import config from "../../config";
import {changePageLoading} from "../../store/layout/action";
import TextAreaOutlined from "../../components/Fields/textAreas";
import Swal from "sweetalert2";
import {changeAvatarLoading} from "../../store/common/action";
import AvatarLoading from "../../components/AvatarLoading";

const Organization = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {mode} = useSelector(state => state.LayoutReducer);

    const [isGettingLocation, setIsGettingLocation] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [avatar, setAvatar] = useState({
        base64: "",
        type: ""
    });
    const [organization, setOrganization] = useState({});

    const updateOrganizationData = () => {
        fetch(config.urlOrigin + '/get-organization', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem('bonusOrgId')
            }
        }).then(async resp => {
            const data = await resp.json();
            if (resp.status === 200) {
                data.location = JSON.parse(data.location);
                await validation.setValues({
                    name: data.name,
                    latitude: data.location[0]?.lat || '',
                    longitude: data.location[0]?.lon || '',
                    phone: data.phone || '',
                    address: data.address || '',
                });
                setOrganization(data);

                if (!data.image_path) {
                    dispatch(changeAvatarLoading(false));
                }

            } else {
                console.log(data.message);
                toast.error(t('server-error'));
            }
        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));

        }).finally(() => dispatch(changePageLoading(false)));
    }

    useEffect(() => {
        updateOrganizationData();
    }, []);

    const handleUpload = (event) => {
        if (event.target.files && event.target.files[0]) {
            dispatch(changeAvatarLoading(true));

            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar({
                    base64: reader.result,
                    type: file.type,
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const validation = useFormik({
        initialValues: {
            name: '',
            latitude: '',
            longitude: '',
            phone: '',
            address: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('required-field')),
            latitude: Yup.number().required(t('required-field')),
            longitude: Yup.number().required(t('required-field')),
            phone: Yup.string().required(t('required-field')).matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, t('phone-invalid')),
        }),
        onSubmit: values => {
            let location = [...organization.location].slice(1);
            location.unshift({
                lat: values.latitude,
                lon: values.longitude
            });
            const data2send = {
                image: avatar,
                name: values.name,
                address: values.address,
                phone: values.phone,
                location
            }
            setIsFetching(true);
            fetch(config.urlOrigin + '/update-organization', {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem('bonusOrgId'),
                },
                body: JSON.stringify(data2send)

            }).then(async resp => {
                const jsonData = await resp.json();
                if (resp.status === 200)
                    toast.success(t('updated'));
                else {
                    toast.error(t('server-error'));
                    console.log(jsonData.message);
                }
            }).catch(error => {
                console.log(error);
                toast.error(t('unknown-error'));

            }).finally(() => setIsFetching(false));
        }
    });

    const getCurrentLocation = () => {
        setIsGettingLocation(true);

        navigator.geolocation.getCurrentPosition((position) => {
            setIsGettingLocation(false);

            validation.setFieldValue('latitude', position.coords.latitude);
            validation.setFieldValue('longitude', position.coords.longitude)

        }, (error) => {
            setIsGettingLocation(false);

            let keyError;
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    keyError = 't-locat-notif';
                    break;
                case error.POSITION_UNAVAILABLE:
                    keyError = 't-locat-notif2';
                    break;
                case error.TIMEOUT:
                    keyError = 't-locat-notif3';
                    break;
                default:
                    keyError = 't-unknown-error';
            }

            Swal.fire({
                icon: 'error',
                text: t(keyError),
                showConfirmButton: true,
                showCloseButton: true
            });
            setIsFetching(false);

        }, {
            maximumAge: 0,
            enableHighAccuracy: true,
            timeout: 5000
        });
    }

    return (
        <Box className="p-2" sx={{pt: 7.5}}>
            <Paper className={"p-2"}>
                <Box display="flex" justifyContent="center">
                    <Box position="relative" display="inline-block">
                        <AvatarLoading
                            src={avatar.base64 || (organization.image_path ? (config.urlOrigin + organization.image_path) : '')}
                        />
                        <IconButton
                            aria-label="upload picture"
                            component="label"
                            color={"default"}
                            sx={{
                                position: 'absolute',
                                bottom: 4,
                                right: 1,
                                backgroundColor: mode === 'light' ? "#c5c5c5" : '#898b8f',
                                '&:hover': {
                                    backgroundColor: mode === 'light' ? "#c5c5c5" : '#898b8f',
                                },
                                '&:active': {
                                    backgroundColor: mode === 'light' ? "#c5c5c5" : '#898b8f',
                                }
                            }}
                        >
                            <input
                                type="file"
                                accept="image/*"
                                style={{display: 'none'}}
                                onChange={handleUpload}
                            />
                            <PhotoCamera/>
                        </IconButton>
                    </Box>
                </Box>

                <form className={"mt-3"} onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <Box className={"mb-1"}>
                        <TextFieldOutlined
                            name={"name"}
                            label={t("organization-name")}
                            error={validation.touched.name && validation.errors.name}
                            value={validation.values.name}
                            handler={validation.handleChange}
                            size={'small'}
                        />
                    </Box>

                    <PhoneNumberField
                        name={"phone"}
                        error={validation.touched.phone && validation.errors.phone}
                        value={validation.values.phone}
                        handler={validation.handleChange}
                        size={'small'}
                    />

                    <Box sx={{display: 'flex', gap: 1, marginTop: 1}}>
                        <TextField
                            name={"latitude"}
                            label={t("latitude")}
                            type="number"
                            value={validation.values.latitude}
                            onChange={validation.handleChange}
                            // inputProps={{step: 'any', min: -90, max: 90}}
                            variant="outlined"
                            size={'small'}
                        />
                        <TextField
                            name={"longitude"}
                            label={t("longitude")}
                            type="number"
                            value={validation.values.longitude}
                            onChange={validation.handleChange}
                            inputProps={{step: 'any', min: -180, max: 180}}
                            variant="outlined"
                            size={'small'}
                        />
                        <IconButton
                            sx={{p: 0.2}}
                            color={'secondary'}
                            disabled={isGettingLocation}
                            onClick={getCurrentLocation}>
                            <MyLocationRounded/>
                        </IconButton>
                    </Box>

                    <Box className={"mt-3"}>
                        <TextAreaOutlined
                            name={"address"}
                            error={validation.touched.address && validation.errors.address}
                            value={validation.values.address}
                            label={t('address')}
                            handler={validation.handleChange}
                            rows={3}
                            placeholder={t('')}
                        />
                    </Box>

                    <Box display="flex" gap={1} justifyContent={"end"} className={"mt-4"}>
                        <ButtonReset
                            text={t('update')}
                            color={'info'}
                            startIcon={<LoopOutlined/>}
                            size={"medium"}
                            variant={"outlined"}
                            handler={() => {
                                validation.handleReset();
                                updateOrganizationData();
                            }}
                        />
                        <ButtonSubmit
                            text={t("save")}
                            size={"medium"}
                            color={'success'}
                            isFetching={isFetching}
                            startIcon={<SaveFillIcon/>}
                            fullWidth={false}/>
                    </Box>
                </form>
            </Paper>
        </Box>
    );
}

export default Organization;