import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import config from "../../../config";
import {changePayments, changeUnpaidPatients, setDoctorData} from "../../../store/common/action";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AddTwoTone, ArchiveTwoTone} from "@mui/icons-material";
import NewPayment from "./NewPayment";
import Archive from "./Archive";
import TabWithIcon from "../../../components/Tabs/TabWithIcon";
import {changePageLoading} from "../../../store/layout/action";

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {id} = useParams();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            await fetch(config.urlOrigin + '/get-user-data?id=' + id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem('bonusOrgId')
                }
            }).then(async resp => {
                const jsonData = await resp.json();
                if (resp.status === 200) {
                    dispatch(setDoctorData(jsonData.data));

                } else {
                    console.log(jsonData.message);
                    toast.error(t('server-error'));
                }
            });

            await fetch(config.urlOrigin + '/get-unpaid-patients?id=' + id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem('bonusOrgId')
                }
            }).then(async resp => {
                const jsonData = await resp.json();
                if (resp.status === 200) {
                    dispatch(changeUnpaidPatients(jsonData.data.map(item => ({
                        ...item,
                        checked: false
                    }))));

                } else {
                    console.log(jsonData.message);
                    toast.error(t('server-error'));
                }
            });

            await fetch(config.urlOrigin + '/get-payments?id=' + id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem('bonusOrgId')
                }
            }).then(async resp => {
                const jsonData = await resp.json();
                if (resp.status === 200) {
                    dispatch(changePayments(jsonData.data));

                } else {
                    console.log(jsonData.message);
                    toast.error(t('server-error'));
                }
            });

            dispatch(changePageLoading(false));

        } catch (err) {
            console.log(err);
            toast.error(t('connection-error'));

            dispatch(changePageLoading(false));
        }
    }

    return (
        <TabWithIcon items={[
            {label: t("make-payment"), icon: <AddTwoTone/>, content: <NewPayment id={id}/>},
            {label: t("payment-archive"), icon: <ArchiveTwoTone/>, content: <Archive/>},
        ]}/>
    );

}

export default Index;