import React from "react";
import {Alert, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {SharesCardArchiveNoActions} from "../../../components/card/sharesCards";

const ArchiveShares = () => {
    const {t} = useTranslation();

    const {sharesArchive} = useSelector(state => state.Common);

    return (
        <Box className="p-2">
            {sharesArchive?.length ? sharesArchive.map((item, index) => (
                    <SharesCardArchiveNoActions item={item} key={index}/>
                )) :
                <Alert severity="info" elevation={2}>{t('no-data-found')}</Alert>}
        </Box>
    );
}

export default ArchiveShares;