import * as React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {Avatar, Divider, ListItemButton, Typography} from '@mui/material';
import {AccessTimeOutlined} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import defaultUser from "../../assets/images/default-company.png";
import config from "../../config";

const ListWithAvatar = ({items}) => {

    const {t} = useTranslation();

    return (
        <List sx={{width: '100%', height: "100%", overflow: "auto"}} disablePadding>
            {items.map((item, index) => <React.Fragment key={index}>
                <ListItemButton
                    alignItems="center"
                    gap={1}
                    disableGutters={true}
                    sx={{paddingBottom: "2px", paddingTop: "2px"}}
                    href={item.url}
                >
                    <ListItemAvatar sx={{minWidth: 53}}>
                        <Avatar
                            alt="Remy Sharp"
                            className={"border-2 border-gray-200 dark:border-gray-600"}
                            sx={{height: 45, width: 45}}
                            src={item['image_path'] ? config.urlOrigin + item['image_path'] : defaultUser}/>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{flexGrow: 1}}
                        primary={item['full_name']}
                        secondary={item.workplace}
                        className='text-custom-blue dark:text-gray-300'
                    />
                    <Typography variant={"body2"} sx={{textAlign: "end"}}>
                        <AccessTimeOutlined
                            sx={{fontSize: 15, marginBottom: '3px'}}
                            color={"inherit"}/>
                        {t('connected')}: {item.connected_time}
                    </Typography>
                </ListItemButton>
                <Divider/>
            </React.Fragment>)
            }
        </List>
    );
}

export default ListWithAvatar
;
