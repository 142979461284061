import React, {useState} from "react";
import {ControlledAccordions} from "../../../components/accordions/accordions";
import {CalendarMonthSharp, InfoOutlined, PeopleAltSharp} from "@mui/icons-material";
import {Alert, Box, Divider} from "@mui/material";
import ListWithToggle from "../../../components/Lists/ListWithToggle";
import {ButtonWithoutIcon, ButtonWithSpinner} from "../../../components/buttons/buttons";
import {useTranslation} from "react-i18next";
import Save2FillIcon from "remixicon-react/Save2FillIcon";
import config from "../../../config";
import {toast} from "react-toastify";


const PatientsList = ({items}) => {
    const {t} = useTranslation();

    const [isFetching, setIsFetching] = useState(false);
    const [managerResps, setManagerResps] = React.useState(
        items?.map(item => ({id: item.id, resp: item.manager_resp})) || []
    );

    const handleManagerRespChanges = (id, val) => {
        setManagerResps(state => {
            let newState = [...state];
            let index = newState.findIndex(item => item.id === id);
            newState[index].resp = val;

            return newState;
        });
    }

    const resetHandler = () => {
        setManagerResps(state => items.map(item => ({id: item.id, resp: item.manager_resp})));
    }

    const saveHadler = () => {
        setIsFetching(true);
        fetch(config.urlOrigin + '/update-patients-manager-reps', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem('bonusOrgId'),
            },
            body: JSON.stringify(managerResps),
        }).then(async response => {
            const data = await response.json();

            if (response.status === 200) {
                toast.error(t('saved'));

            } else {
                console.log(data.message);
                toast.error(t('server-error'));
            }

        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));

        }).finally(() => setIsFetching(false));
    }

    return (
        <React.Fragment>
            <Divider/>
            <ListWithToggle items={items} managerResps={managerResps} handlerChange={handleManagerRespChanges}/>
            <Box className={"text-end mt-2"}>
                <ButtonWithoutIcon
                    sx={{textTransform: "capitalize"}}
                    size={'small'}
                    color={"info"}
                    variant={"outlined"}
                    text={t('reset')}
                    elevation={false}
                    handler={resetHandler}
                />
                <ButtonWithSpinner
                    sx={{marginLeft: "8px", textTransform: "capitalize"}}
                    size={'small'}
                    color={"success"}
                    variant={"contained"}
                    text={t('save')}
                    elevation={false}
                    startIcon={<Save2FillIcon/>}
                    handler={saveHadler}
                    isFetching={isFetching}
                />
            </Box>
        </React.Fragment>
    );
}

const ListOfLast30DaysSent = ({items}) => {
    const {t} = useTranslation();

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box className="p-2">
            {Object.keys(items).length > 0 ? Object.entries(items).map(([key, value], index) => (
                <ControlledAccordions keyUnique={key}
                                      expanded={expanded}
                                      key={index}
                                      handleChange={handleChange}
                                      primaryHeader={
                                          <Box display="flex" gap={1}>
                                              <CalendarMonthSharp color={"info"}/>
                                              {key}
                                          </Box>
                                      }
                                      secondaryHeader={
                                          <Box display="flex" gap={1}>
                                              <PeopleAltSharp color={"secondary"}/>
                                              {value.length}
                                          </Box>
                                      }
                                      content={
                                          <React.Fragment>
                                              <PatientsList items={value}/>
                                          </React.Fragment>
                                      }/>
            )) : <Alert icon={<InfoOutlined fontSize="inherit"/>} elevation={1} severity="info">
                {t('no-data-found')}
            </Alert>}
        </Box>
    );
}

export default ListOfLast30DaysSent;


