import React, {useEffect} from "react";
import TabWithIcon from "../../../components/Tabs/TabWithIcon";
import {useTranslation} from "react-i18next";
import {AddTwoTone, TableRowsTwoTone} from "@mui/icons-material";
import ListOfPatients from "./ListOfPatients";
import AddNewPatients from "./AddNewPatient";
import {useDispatch} from "react-redux";
import config from "../../../config";
import {toast} from "react-toastify";
import {changeDoctorPatients} from "../../../store/common/action";
import {changePageLoading} from "../../../store/layout/action";

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        fetch(config.urlOrigin + '/get-patients-doctor-sent?id=' + localStorage.getItem("bonusUserId"), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem('bonusOrgId')
            }
        }).then(async resp => {
            const jsonData = await resp.json();
            if (resp.status === 200) {
                dispatch(changeDoctorPatients(jsonData.data.sort((a, b) => b.id - a.id)));

            } else {
                console.log(jsonData.message);
                toast.error(t('server-error'));
            }
        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));

        }).finally(() => dispatch(changePageLoading(false)));

    }, []);

    return (
        <React.Fragment>
            <TabWithIcon items={[
                {label: t('list'), icon: <TableRowsTwoTone/>, content: <ListOfPatients/>},
                {label: t('add'), icon: <AddTwoTone/>, content: <AddNewPatients/>},
            ]}/>
        </React.Fragment>
    );
}

export default Index;