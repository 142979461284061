import React from 'react';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from "react-redux";
import {changeAvatarLoading} from "../store/common/action";

function AvatarLoading({src}) {
    const dispatch = useDispatch();

    const {isAvatarLoading} = useSelector(state => state.Common);

    const handleImageLoad = () => {
        dispatch(changeAvatarLoading(false));
    }

    return (
        <Box position="relative" display="inline-flex">
            {isAvatarLoading && (
                <CircularProgress
                    size={40}
                    style={{position: 'absolute', top: '50%', left: '50%', marginTop: -20, marginLeft: -20}}
                />
            )}
            <Avatar
                className={"border-gray-100 dark:border-gray-600 border-4"}
                alt="User Avatar"
                src={src}
                style={{opacity: isAvatarLoading ? 0.5 : 1}}
                onLoad={handleImageLoad}
                onError={handleImageLoad}
                sx={{width: 150, height: 150}}
            />
        </Box>
    );
}

export default AvatarLoading;
