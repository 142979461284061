import React, {useEffect} from "react";
import TabWithIcon from "../../../components/Tabs/TabWithIcon";
import {useTranslation} from "react-i18next";
import ListOfUsers from "./ListOfUsers";
import {GroupAddTwoTone, GroupsTwoTone} from "@mui/icons-material";
import {changePageLoading} from "../../../store/layout/action";
import {useDispatch} from "react-redux";
import config from "../../../config";
import {toast} from "react-toastify";
import {changeDoctorsConnA, changeDoctorsConnM} from "../../../store/common/action";
import moment from "moment/moment";
import AdminAssigned from "./AdminAssigned";

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        fetch(config.urlOrigin + '/get-users-of-manager?id=' + localStorage.getItem("bonusUserId"), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem('bonusOrgId')
            }
        }).then(async resp => {
            const jsonData = await resp.json();
            if (resp.status === 200) {
                const [managerConnected, adminConnected] = jsonData.data
                    .sort((a, b) => b.connected_time.localeCompare(a.connected_time))
                    .reduce(([manager, admin], current) => {
                        if (current.who_connected === 1)
                            manager.push({
                                ...current,
                                connected_time: moment(current.connected_time).format("DD.MM.YYYY HH:mm:ss"),
                                url: "/manager/doctor-requests/" + current.id
                            });
                        else
                            admin.push({
                                ...current,
                                connected_time: moment(current.connected_time).format("DD.MM.YYYY HH:mm:ss"),
                                url: "/manager/doctor-requests/" + current.id
                            });

                        return [manager, admin];
                    }, [[], []]);

                dispatch(changeDoctorsConnM(managerConnected));
                dispatch(changeDoctorsConnA(adminConnected));

            } else {
                console.log(jsonData.message);
                toast.error(t('server-error'));
            }
        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));

        }).finally(() => dispatch(changePageLoading(false)));

    }, []);

    return (
        <React.Fragment>
            <TabWithIcon items={[
                {label: t('the-connected'), icon: <GroupsTwoTone/>, content: <ListOfUsers/>},
                {label: t('task'), icon: <GroupAddTwoTone/>, content: <AdminAssigned/>},
            ]}/>
        </React.Fragment>
    );
}

export default Index;