import React, {useEffect} from "react";
import {Alert, Box} from "@mui/material";
import config from "../../config";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {changePageLoading} from "../../store/layout/action";
import {useDispatch, useSelector} from "react-redux";
import TableFixedHeader from "../../components/tables/TableFixedHeader";
import {formatNumber} from "../../helpers/common";

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {isLoading} = useSelector(state => state.LayoutReducer);

    const [prices, setPrices] = React.useState([]);

    useEffect(() => {
        fetch(config.urlOrigin + '/get-services-prices', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem("bonusOrgId")
            }
        }).then(async resp => {
            const jsonData = await resp.json();
            if (resp.status === 200) {
                setPrices(jsonData.data);

            } else {
                console.log(jsonData.message);
                toast.error(t('server-error'));
            }

        }).catch(error => {
            console.log(error);
            toast.error(t('connection-error'));

        }).finally(() => dispatch(changePageLoading(false)));

    }, []);

    return (
        <Box className={"p-2"} sx={{pt: 7.5}}>
            {
                !isLoading && (
                    prices.length > 0 ?
                        <TableFixedHeader rows={prices}/> :
                        <Alert severity={"info"} elevation={2}>{t('no-data-found')}</Alert>
                )
            }
        </Box>
    );

}

export default Index;