import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {useTheme} from "@mui/material";

export function RowRadioButtonsGroup({label, name, value, items, handler}) {
    const theme = useTheme();

    return (
        <FormControl>
            {label && <FormLabel id="row-radio-button">{label}</FormLabel>}
            <RadioGroup
                row
                aria-labelledby="row-radio-button"
                name={name}
                onChange={handler}
                value={value}
                sx={{
                    color:theme.palette.text.secondary,
                }}
            >
                {items.map((item, idx) => <FormControlLabel key={idx} value={item.value} control={<Radio/>}
                                                            label={item.label}/>)}
            </RadioGroup>
        </FormControl>
    );
}
