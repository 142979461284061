import React from "react";
import {Alert, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {BonusesCardActiveNoActions} from "../../../components/card/bonusesCard";

const ActiveBonuses = () => {
    const {t} = useTranslation();

    const {bonusesActive} = useSelector(state => state.Common);

    return (
        <Box className="p-2 pb-0">
            {bonusesActive?.length > 0 ?
                bonusesActive.map((item, index) => (
                    <BonusesCardActiveNoActions item={item}
                                                key={index}/>
                )) :
                <Alert severity="info" elevation={2}>{t('no-data-found')}</Alert>}
        </Box>
    );
}

export default ActiveBonuses;