import * as React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

export function ButtonGroupsBasic({
                                      items,
                                      variant = "contained",
                                      handler,
                                      size = 'small',
                                      value
                                  }) {
    return (
        <ButtonGroup
            disableElevation
            size={size}
            aria-label="Disabled button group"
            color={"inherit"}
        >
            {items.map((item, idx) => (
                <Button
                    key={idx}
                    variant={value === item.value?'contained':"outlined"}
                    onClick={() => {
                        handler(item.value)
                    }} color={item.color}>{item.text}</Button>))}

        </ButtonGroup>
    );
}
