import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {IconButton} from "@mui/material";

export function ToolipClickIconBtn({
                                       handler,
                                       icon,
                                       size = "medium",
                                       title
                                   }) {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={title}
                >
                    <IconButton
                        size={'small'}
                        onClick={() => {
                            handleTooltipOpen();
                            handler();
                        }}>
                        {icon}
                    </IconButton>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
}
