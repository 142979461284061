import {
    BONUSES_ACTIVE,
    BONUSES_ARCHIVE,
    DOCTOR_DATA,
    PATIENTS,
    SHARES_ACTIVE,
    SHARES_ARCHIVE,
    USER,
    DEMAND_RESPONSES,
    DEMANDS_TO_RESPOND,
    MANAGERS,
    DOCTORS_NO_MANAGER,
    DOCTOR_PATIENTS,
    ADD_DOCTOR_PATIENT,
    DOCTORS_MANAGER_CONNECTED,
    DOCTORS_ADMIN_CONNECTED,
    AVATAR_LOADING, UNPAIND_PATIENTS, PAYMENTS, ADD_PAYMENTS
} from "./actionType";

export const setUserData = (data) => ({
    type: USER,
    payload: data
});

export const setDoctorData = (data) => ({
    type: DOCTOR_DATA,
    payload: data
})

export const setPatientsData = (data) => ({
    type: PATIENTS,
    payload: data
})

export const changeSharesActive = (data) => ({
    type: SHARES_ACTIVE,
    payload: data
});

export const changeSharesArchive = (data) => ({
    type: SHARES_ARCHIVE,
    payload: data
});

export const changeBonusesActive = (data) => ({
    type: BONUSES_ACTIVE,
    payload: data
});

export const changeBonusesArchive = (data) => ({
    type: BONUSES_ARCHIVE,
    payload: data
});

export const changeDemandResponses = (data) => ({
    type: DEMAND_RESPONSES,
    payload: data
});

export const changeDemandsToRespond = (data) => ({
    type: DEMANDS_TO_RESPOND,
    payload: data
});

export const changeManagers = (data) => ({
    type: MANAGERS,
    payload: data
});

export const changeDoctorsNoManager = (data) => ({
    type: DOCTORS_NO_MANAGER,
    payload: data
});

export const changeDoctorPatients = (data) => ({
    type: DOCTOR_PATIENTS,
    payload: data
});

export const addDoctorPatient = (data) => ({
    type: ADD_DOCTOR_PATIENT,
    payload: data
});

export const changeDoctorsConnM = (data) => ({
    type: DOCTORS_MANAGER_CONNECTED,
    payload: data
});

export const changeDoctorsConnA = (data) => ({
    type: DOCTORS_ADMIN_CONNECTED,
    payload: data
});

export const changeAvatarLoading = (data) => ({
    type: AVATAR_LOADING,
    payload: data
});

export const changeUnpaidPatients = (data) => ({
    type: UNPAIND_PATIENTS,
    payload: data
});

export const changePayments = (data) => ({
    type: PAYMENTS,
    payload: data
});

export const addPayments = (data) => ({
    type: ADD_PAYMENTS,
    payload: data
});

