import React from "react";

const Footer = () => {

    return (
        <div className="footer bg-custom-blue text-gray-200">
            <p className="mb-0 text-center tracking-wide text-[12px]">
                © 2024 Bonus Bot.
                <span className="ms-2">
                    Created & Developed <i className="ri-heart-fill text-[1rem] tx-red"></i> by ZeroSoft
                </span>
            </p>
        </div>
    );
}

export default Footer;