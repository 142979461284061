import {FormHelperText, InputAdornment, TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputMask from 'react-input-mask';
import Cleave from "cleave.js/react";
import React from "react";

export const TextFieldOutlined = ({label, classes, value, handler, name, error, size = "small"}) => {
    return (
        <FormControl fullWidth>
            <TextField
                className={classes}
                name={name}
                label={label}
                variant="outlined"
                value={value}
                onChange={handler}
                error={!!error}
                size={size}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
}

export const TextFieldOutlinedWithoutHelper = ({
                                                   label,
                                                   placeholder = '',
                                                   classes,
                                                   value,
                                                   handler,
                                                   name,
                                                   error,
                                                   size = "small"
                                               }) => {
    return (
        <FormControl fullWidth>
            <TextField
                className={classes}
                name={name}
                label={label}
                variant="outlined"
                value={value}
                onChange={handler}
                error={!!error}
                size={size}
                placeholder={placeholder}
            />
        </FormControl>
    );
}
export const TextFieldOutlinedIcon = ({
                                          placeholder,
                                          classes,
                                          value,
                                          handler,
                                          error,
                                          icon,
                                          name,
                                          size = "medium",
                                          margin = "dense"
                                      }) => {
    return (
        <FormControl fullWidth>
            <TextField
                className={classes}
                variant="outlined"
                name={name}
                value={value}
                onChange={handler}
                error={!!error}
                size={size}
                placeholder={placeholder}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {icon}
                        </InputAdornment>
                    )
                }}
                margin={margin}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
}

export const PhoneNumberField = ({
                                     value,
                                     handler,
                                     error,
                                     classes,
                                     name,
                                     size = "medium",
                                     margin = "dense"
                                 }) => {
    return (
        <FormControl fullWidth>
            <InputMask
                mask={'(99) 999-99-99'}
                name={name}
                value={value}
                onChange={handler}
            >
                {(inputProps) => (
                    <TextField
                        {...inputProps}
                        className={classes}
                        variant="outlined"
                        placeholder="(xx) xxx-xx-xx"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    +998
                                </InputAdornment>
                            )
                        }}
                        error={!!error}
                        size={size}
                        margin={margin}
                    />
                )}
            </InputMask>
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
};

// Forward the ref in CleaveInputDate
const CleaveInputDate = React.forwardRef(({inputRef, ...other}, ref) => (
    <Cleave
        {...other}
        htmlRef={ref}
        options={{
            date: true,
            datePattern: ['d', 'm', 'Y'],
            delimiter: '.',
        }}
    />
));

// DateFieldDDMMYYYY component
export const DateFieldDDMMYYYY = ({
                                      name,
                                      label,
                                      error,
                                      value,
                                      handler,
                                      size = "medium",
                                      margin = "dense"
                                  }) => (
    <FormControl fullWidth>
        <TextField
            name={name}
            label={label}
            variant="outlined"
            placeholder="DD.MM.YYYY"
            InputProps={{
                inputComponent: CleaveInputDate // Pass the forwardRef component
            }}
            value={value}
            onChange={handler}
            error={!!error}
            size={size}
            margin={margin}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
);


const CleaveInputPhone = React.forwardRef(({inputRef, ...other}, ref) => (
    <Cleave
        {...other}
        htmlRef={ref}
        options={{
            delimiters: ['(', ') ', '-', '-'],
            blocks: [0, 2, 3, 2, 2],
            numericOnly: true,
        }}
    />
));

export const PhoneNumberFieldLabel = ({
                                          name,
                                          label,
                                          error,
                                          value,
                                          handler,
                                          size = "medium",
                                          margin = "dense"
                                      }) => (
    <FormControl fullWidth>
        <TextField
            name={name}
            label={label}
            variant="outlined"
            placeholder="(xx) xxx-xx-xx"
            InputProps={{
                inputComponent: CleaveInputPhone,
                startAdornment: (
                    <InputAdornment position="start">
                        +998
                    </InputAdornment>
                )
            }}
            value={value}
            onChange={handler}
            error={!!error}
            size={size}
            margin={margin}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
);


export const TextAreaIcon = ({
                                 placeholder,
                                 classes,
                                 value,
                                 handler,
                                 error,
                                 icon,
                                 name,
                                 size = "medium",
                                 margin = "dense",
                                 rows = 2
                             }) => {
    return (
        <FormControl fullWidth>
            <TextField
                variant="outlined"
                multiline
                rows={rows}
                name={name}
                value={value}
                onChange={handler}
                error={!!error}
                size={size}
                placeholder={placeholder}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {icon}
                        </InputAdornment>
                    )
                }}
                margin={margin}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
}

export const TextFielSearch = ({
                                   classes,
                                   value,
                                   handler,
                                   size = "small",
                                   placeholder
                               }) => {
    return (
        <FormControl fullWidth>
            <TextField
                className={classes}
                variant="outlined"
                value={value}
                onChange={handler}
                size={size}
                placeholder={placeholder}
            />
        </FormControl>
    );
}
