import * as React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import {Divider, ListItemButton, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

const ListText = ({items}) => {
    const {t} = useTranslation();

    return (
        <List sx={{width: '100%', height: "100%", overflow: "auto"}} disablePadding>
            {items.map((item, index) => <React.Fragment key={index}>
                <ListItemButton
                    alignItems="center"
                    gap={1}
                    disableTouchRipple={true}
                    disableRipple={true}
                    disableGutters={true}
                    sx={{paddingBottom: "1px", paddingTop: "1px"}}
                    href={item.url}
                >
                    <ListItemText
                        sx={{flexGrow: 1}}
                        primary={item['full_name']}
                        className='text-custom-blue dark:text-gray-300'
                    />
                    <Box className={"flex flex-col items-end"}>
                        <Typography variant={"body2"} sx={{textAlign: "end", textWrap: "nowrap"}}>
                            {item.birthday}
                        </Typography>
                        <Typography variant={"body2"} sx={{textAlign: "end", textWrap: "nowrap"}}>
                            {item.phone || '-'}
                        </Typography>
                    </Box>
                </ListItemButton>
                {index < items.length - 1 && <Divider/>}
            </React.Fragment>)
            }
        </List>
    );
}

export default ListText;
