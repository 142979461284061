import React from "react";
import {Alert, Box, Paper} from "@mui/material";
import ListWithAvatar from "../../../components/Lists/ListWithAvatar";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const ListOfUsers = () => {
    const {t} = useTranslation();

    const {doctorsConnM} = useSelector(state => state.Common);

    return (
        <Box className="p-2">
            {
                doctorsConnM.length > 0 ?
                    <Paper className={"p-2"} sx={{backgroundColor: "background.paper"}}>
                        <ListWithAvatar items={doctorsConnM}/>
                    </Paper> :
                    <Alert severity="info" elevation={1}>{t('no-data-found')}</Alert>
            }
        </Box>
    );
}

export default ListOfUsers;