import React, {useEffect, useState} from "react";
import config from "../../config";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {t} from "i18next";
import {Box, CircularProgress, ClickAwayListener, IconButton, Paper, Tooltip, Zoom} from "@mui/material";
import {changePageLoading} from "../../store/layout/action";
import TelegramFillIcon from "remixicon-react/TelegramFillIcon";

const QRcode = () => {
    const dispatch = useDispatch();

    const [isFetching, setIsFetching] = useState(true);
    const [qrcode, setQrcode] = useState(null);
    const [botURL, setBotURL] = useState(null);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    useEffect(() => {
        dispatch(changePageLoading(false));

        fetch(config.urlOrigin + '/create-qr-code', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem('bonusOrgId')
                },
                body: JSON.stringify({
                    id: +localStorage.getItem('bonusUserId')
                })
            }
        ).then(async resp => {
            const data = await resp.json();

            if (resp.status === 200) {
                setQrcode(data.path);

            } else {
                toast.error(t('server-error'));
                console.log(data.message);
            }
        }).catch(error => {
            console.log(error);
            toast.error(t('unknown-error'));

        }).finally(() => setIsFetching(false));

        fetch(config.urlOrigin + '/get-organization', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem('bonusOrgId')
            }
        }).then(async resp => {
            const data = await resp.json();

            if (resp.status === 200) {
                setBotURL(data.bot_url);
            }
        }).catch(err => {
            console.log(err);
        });

    }, []);

    const copyLink = () => {
        navigator.clipboard.writeText(botURL + '?start=1');
        setIsTooltipOpen(true);
    }


    return (
        <Box className={"p-2"} sx={{pt: 7.5}}>
            <Paper className={"p-2"}>
                <Box display="flex" alignItems="center" justifyContent="center" minHeight={"50vh"}>
                    {isFetching ? <CircularProgress/> :
                        <img src={qrcode && (config.urlOrigin + '/' + qrcode)} className={"w-full"} alt="QR Code"/>}
                </Box>
            </Paper>

            <Paper className={"p-1 mt-2 text-end"}>
                <Box>
                    <ClickAwayListener onClickAway={() => {
                        setIsTooltipOpen(false);
                    }}>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={() => {
                                setIsTooltipOpen(false);
                            }}
                            open={isTooltipOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={t('copied')}
                            TransitionComponent={Zoom}
                        >
                            <IconButton color={"primary"} onClick={copyLink}>
                                <TelegramFillIcon/>
                            </IconButton>
                        </Tooltip>
                    </ClickAwayListener>
                </Box>
            </Paper>
        </Box>
    );
}

export default QRcode;