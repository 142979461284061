import React, {useEffect, useState} from "react";
import config from "../../../config";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Alert, Avatar, Box, Typography} from "@mui/material";
import {ControlledAccordions} from "../../../components/accordions/accordions";
import moment from "moment";
import {useDispatch} from "react-redux";
import {setDoctorData} from "../../../store/common/action";
import defaultUser from "../../../assets/images/user-dummy-img.jpg";
import {ButtonLink} from "../../../components/buttons/buttons";
import {ReceiptSharp} from "@mui/icons-material";
import {formatCardNumber} from "../../../helpers/common";
import {changePageLoading} from "../../../store/layout/action";

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {id} = useParams();

    const [doctors, setDoctors] = useState([]);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        fetch(config.urlOrigin + '/get-users-of-manager?id=' + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem('bonusOrgId')
            }
        }).then(async resp => {
            const jsonData = await resp.json();

            if (resp.status === 200) {
                setDoctors(jsonData.data);

            } else {
                console.log(jsonData.message);
                toast.error(t('server-error'));
            }
        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));

        }).finally(() => {
            dispatch(changePageLoading(false));
        });

        fetch(config.urlOrigin + '/get-user-data?id=' + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem('bonusOrgId')
            }
        }).then(async resp => {
            const jsonData = await resp.json();

            if (resp.status === 200) {
                dispatch(setDoctorData(jsonData.data));

            } else {
                console.log(jsonData.message);
                toast.error(t('server-error'));
            }
        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));
        });

    }, []);

    return (
        <Box className={"p-2"} sx={{pt: 7.5}}>
            {
                doctors.length > 0 ? (
                    doctors.map((doctor, index) =>
                        <ControlledAccordions
                            key={index}
                            keyUnique={doctor.id}
                            expanded={expanded}
                            handleChange={handleChange}
                            primaryHeader={
                                <Box display={"flex"} gap={1} alignItems={"center"}>
                                    <Avatar
                                        className={"border"}
                                        src={doctor.image_path ? config.urlOrigin + doctor.image_path : defaultUser}
                                        alt={doctor.name}/>
                                    <Typography fontSize={16}>{doctor.full_name}</Typography>
                                </Box>
                            }
                            secondaryHeader={''}
                            content={
                                <Box>
                                    <Box display={"flex"} gap={1} justifyContent={"space-between"}>
                                        <Typography variant={"body2"} component={"p"}
                                                    fontSize={14}
                                                    textTransform={"capitalize"}>{t('speciality')}:</Typography>
                                        <Typography variant={"body2"} component={"p"} fontSize={14}
                                                    color={"text.secondary"}>{doctor.speciality}</Typography>
                                    </Box>
                                    <Box display={"flex"} gap={1} justifyContent={"space-between"}>
                                        <Typography variant={"body2"} component={"p"}
                                                    fontSize={14}
                                                    textTransform={"capitalize"}>{t('workplace')}:</Typography>
                                        <Typography variant={"body2"} component={"p"} fontSize={14}
                                                    color={"text.secondary"}>{doctor.workplace}</Typography>
                                    </Box>
                                    <Box display={"flex"} gap={1} justifyContent={"space-between"}>
                                        <Typography variant={"body2"} component={"p"}
                                                    fontSize={14}
                                                    textTransform={"capitalize"}>{t('phone')}:</Typography>
                                        <Typography variant={"body2"} component={"p"} fontSize={14}
                                                    color={"text.secondary"}>{doctor.phone}</Typography>
                                    </Box>
                                    <Box display={"flex"} gap={1} justifyContent={"space-between"}>
                                        <Typography variant={"body2"} component={"p"}
                                                    fontSize={14}
                                                    textTransform={"capitalize"}>{t('connected-time')}:</Typography>
                                        <Typography variant={"body2"} component={"p"} fontSize={14}
                                                    color={"text.secondary"}>
                                            {moment(doctor.connected_time).format("DD.MM.YYYY, HH:mm:ss")}
                                        </Typography>
                                    </Box>

                                    <Box display={"flex"} gap={1} justifyContent={"space-between"}>
                                        <Typography variant={"body2"} component={"p"}
                                                    fontSize={14}>{t('card-number')}:</Typography>
                                        <Typography variant={"body2"} component={"p"} fontSize={14}
                                                    color={"text.secondary"}>
                                            {doctor.card_number ? formatCardNumber(doctor.card_number) : '-'}
                                        </Typography>
                                    </Box>

                                    <Box display={"flex"} gap={1} justifyContent={"space-between"}>
                                        <Typography variant={"body2"} component={"p"}
                                                    fontSize={14}>{t('card-holder')}:</Typography>
                                        <Typography variant={"body2"} component={"p"} fontSize={14}
                                                    color={"text.secondary"}>
                                            {doctor.card_holder || '-'}
                                        </Typography>
                                    </Box>

                                    <Box display={"flex"} gap={1} justifyContent={"space-between"}>
                                        <Typography variant={"body2"} component={"p"}
                                                    fontSize={14}>{t('expires')}:</Typography>
                                        <Typography variant={"body2"} component={"p"} fontSize={14}
                                                    color={"text.secondary"}>
                                            {doctor.card_expires || '-'}
                                        </Typography>
                                    </Box>

                                    <ButtonLink
                                        variant={"outlined"}
                                        color={"secondary"}
                                        startIcon={<ReceiptSharp/>}
                                        link={'/admin/manager-doctors/payments/' + doctor.id}
                                        text={t('payments')}
                                        size={"small"}
                                        sx={{
                                            mt: 1
                                        }}
                                    />
                                </Box>
                            }
                        />)
                ) : <Alert severity={"info"} elevation={1}>{t('no-data-found')}</Alert>
            }
        </Box>
    );

}

export default Index;
