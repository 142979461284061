import {IS_LOADING, LANGUAGE, MODE} from "./actionType";

export const changeMode = (data) => ({
    type: MODE,
    payload: data
});

export const changePageLoading = (data) => ({
    type: IS_LOADING,
    payload: data
});

export const changeLanguage = (data) => ({
    type: LANGUAGE,
    payload: data
});

