import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box} from "@mui/material";
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

export function ControlledAccordions({
                                         expanded,
                                         keyUnique,
                                         primaryHeader,
                                         secondaryHeader,
                                         handleChange,
                                         content
                                     }) {


    return (
        <Accordion
            expanded={expanded === keyUnique}
            onChange={handleChange(keyUnique)}
            sx={{
                '&.Mui-expanded': {
                    margin: '6px 0'
                },
                '&.css-xskrbd-MuiButtonBase-root-MuiAccordionSummary-root': {
                    padding: '6px 0',
                    minHeight: "50px"
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                    '& .css-eqpfi5-MuiAccordionSummary-content': {
                        gap: 3,
                        margin: "10px 0",
                        alignItems: "center"
                    },
                }}
            >
                <Box sx={{flexShrink: 0}}>
                    {primaryHeader}
                </Box>
                <Box>{secondaryHeader}</Box>
            </AccordionSummary>
            <AccordionDetails sx={{p: 1.2, pt: 0}}>
                {content}
            </AccordionDetails>
        </Accordion>
    );
}


const AccordionCustom = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummaryCustom = styled((props) => (
    <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetailsCustom = styled(AccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export function ControlledAccordionsInline({
                                               expanded,
                                               keyUnique,
                                               primaryHeader,
                                               secondaryHeader,
                                               handleChange,
                                               content
                                           }) {

    return (
        <AccordionCustom expanded={expanded === keyUnique} onChange={handleChange(keyUnique)}>
            <AccordionSummaryCustom aria-controls="panel1d-content" id="panel1d-header">
                <Box className={"flex-grow"}>
                    {primaryHeader}
                </Box>
                <Box>{secondaryHeader}</Box>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
                {content}
            </AccordionDetailsCustom>
        </AccordionCustom>
    );
}
