import * as React from 'react';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {CalendarTodaySharp} from "@mui/icons-material";
import {MobileDatePicker} from "@mui/x-date-pickers";

export function DatePickerOutlined({
                                       label,
                                       handler,
                                       name,
                                       value
                                   }) {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <MobileDatePicker
                    label={label}
                    views={['year', 'month', 'day']}
                    value={value}
                    onChange={handler}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name={name}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <CalendarTodaySharp />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}