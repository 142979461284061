import React from "react";
import ListWIthStatus from "../../../components/Lists/ListWIthStatus";
import {Alert, Box, CircularProgress, Paper} from "@mui/material";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const ListOfPatients = () => {
    const {t} = useTranslation();

    const {patients} = useSelector(state => state.Common);
    const {isLoading} = useSelector(state => state.LayoutReducer);

    return (
        <Box className="p-2">
            {
                !isLoading && (
                    patients.length > 0 ?
                        <Paper className={"p-2"} sx={{backgroundColor: "background.paper"}}>
                            <ListWIthStatus items={patients}/>
                        </Paper> :
                        <Alert severity="info" elevation={2}>{t('no-data-found')}</Alert>
                )
            }
        </Box>
    );
}

export default ListOfPatients;