import * as React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {Box, Divider, IconButton, ListItemButton,} from '@mui/material';
import {AccessTimeOutlined, CheckBoxSharp, DisabledByDefaultSharp, PendingSharp} from "@mui/icons-material";
import moment from "moment";

const ListWithToggle = ({items, handlerChange, managerResps}) => {

    return (
        <List sx={{width: '100%', height: "100%", overflow: "auto", p: 0}}>
            {items.map((item, index) => <React.Fragment key={index}>
                <ListItemButton
                    alignItems="center" sx={{py: 0}}
                    disableTouchRipple={true}
                    disableGutters={true}
                >
                    <ListItemText
                        primary={item['full_name']}
                        className='text-custom-blue dark:text-gray-300'
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{display: 'inline'}}
                                    component="span"
                                    variant="body"
                                    className='text-custom-black dark:text-gray-400 font-normal'
                                >
                                    {item['birthday'] || '-'}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                    <Box display={'flex'} flexDirection={"column"} alignItems={'end'} justifyContent={'end'}>
                        {managerResps[index]?.resp === 0 ?
                            <IconButton color={"info"} onClick={() => handlerChange(item.id, 1)}>
                                <PendingSharp/>
                            </IconButton> : managerResps[index]?.resp === 1 ?
                                <IconButton color={"success"} onClick={() => handlerChange(item.id, 2)}>
                                    <CheckBoxSharp/>
                                </IconButton> :
                                <IconButton color={"error"} onClick={() => handlerChange(item.id, 0)}>
                                    <DisabledByDefaultSharp/>
                                </IconButton>}
                        <Typography
                            sx={{marginBottom: "3px", fontSize: "13px", textAlign: "end", textWrap: "nowrap"}}
                            component="span"
                            variant="body1"
                            className='text-custom-black dark:text-gray-400 font-normal'
                        >
                            <AccessTimeOutlined
                                sx={{fontSize: "14px", mt: "-3px"}}/> {moment(item['created_time']).format('HH:mm:ss')}
                        </Typography>
                    </Box>
                </ListItemButton>
                <Divider/>
            </React.Fragment>)
            }
        </List>
    );
}

export default ListWithToggle;
