import React, {useEffect, useState} from "react";
import {Box, Paper, Typography} from "@mui/material";
import {changePageLoading} from "../../store/layout/action";
import {useDispatch} from "react-redux";
import config from "../../config";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";

const Instructions = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [instructionsHTML, setInstructionsHTML] = useState("");
    const [instructionsVIDEO, setInstructionsVIDEO] = useState([]);

    const fetchAllData = async () => {
        try {
            const responseVideos = await fetch(config.urlOrigin + '/get-instructions-videos', {
                method: 'GET',
                headers: {
                    authorization: localStorage.getItem("bonusOrgId"),
                }
            });
            const jsonVideos = await responseVideos.json();
            if (responseVideos.status === 200) {
                setInstructionsVIDEO(jsonVideos);
            } else {
                toast.error(t('server-error'));
                console.log(jsonVideos.message);
            }

            dispatch(changePageLoading(false));

        } catch (err) {
            console.log(err);
            toast.error(t('connection-error'));
        }
    }

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        fetch(config.urlOrigin + '/get-instructions-text?lang=' + i18n.language, {
            method: 'GET',
            headers: {
                authorization: localStorage.getItem("bonusOrgId"),
            }

        }).then(async resp => {
            const textData = await resp.text();
            if (resp.status === 200) {
                setInstructionsHTML(textData);
            } else {
                console.log(textData);
            }
        }).catch(err => {
            console.log(err)
        });
    }, [i18n.language]);

    return (
        <Box className={"p-2"} sx={{pt: 7.5}}>
            <Paper className={"p-2"}>
                <Typography variant="h6"
                            component="h6"
                            sx={{
                                textAlign: "center",
                                textTransform: "uppercase",
                                fontSize: "17px"
                            }}>
                    {t('instructions')}
                </Typography>
                {instructionsVIDEO.map((video, index) => (
                    <div key={index} className={"mt-3"}>
                        <video className={"rounded shadow-md"} controls>
                            <source src={config.urlOrigin + video} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                ))}
                <div className={"mt-3"} dangerouslySetInnerHTML={{__html: instructionsHTML}}/>
            </Paper>
        </Box>
    )
        ;

}

export default Instructions