import React from "react"
import {Alert, Box, Paper} from "@mui/material";
import TextAreaOutlined from "../../../components/Fields/textAreas";
import {ButtonReset, ButtonSubmitEndIcon} from "../../../components/buttons/buttons";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {SendSharp} from "@mui/icons-material";
import config from "../../../config";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {changeDemandResponses} from "../../../store/common/action";

const CreateNew = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {demandResponses} = useSelector(state => state.Common);

    const [isFetching, setIsFetching] = React.useState(false);

    const validation = useFormik({
        initialValues: {
            demand: ""
        },
        validationSchema: Yup.object().shape({
            demand: Yup.string().required()
        }),
        onSubmit: values => {
            setIsFetching(true);
            fetch(config.urlOrigin + '/send-user-demand', {
                method: 'POST',
                headers: {
                    "Content-type": "application/json",
                    authorization: localStorage.getItem("bonusOrgId"),
                },
                body: JSON.stringify({
                    userId: +localStorage.getItem("bonusUserId"),
                    text: values.demand
                })

            }).then(async resp => {
                const jsonData = await resp.json();
                if (resp.status === 200) {
                    toast.success(t('sent'));

                    dispatch(changeDemandResponses([...demandResponses, jsonData.data]));

                } else {
                    toast.error(t('server-error'));
                    console.log(jsonData.message);
                }

            }).catch(err => {
                console.log(err);
                toast.error(t('connection-error'));

            }).finally(() => setIsFetching(false));
        }
    })


    return (
        <Box className={"p-2"}>
            <Alert color={"info"} severity={"info"} elevation={2}>
                {t('demand-info')}
            </Alert>

            <Paper className={"p-2 mt-2"}>
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <TextAreaOutlined
                        handler={validation.handleChange}
                        value={validation.values.demand}
                        label={t('requirement')}
                        name={'demand'}
                        error={validation.touched.demand && validation.errors.demand}
                        rows={4}
                        placeholder={t('requirement-place')}
                    />
                    <Box className={"mt-3 flex justify-end gap-2"}>
                        <ButtonReset
                            text={t('clear')}
                            variant={"outlined"}
                            color={"primary"}
                            handler={validation.handleReset}
                        />
                        <ButtonSubmitEndIcon
                            text={t('send')}
                            isFetching={isFetching}
                            icon={<SendSharp/>}
                            color={"success"}
                            fullWidth={false}
                        />
                    </Box>
                </form>
            </Paper>
        </Box>
    );
}

export default CreateNew;