import React from "react";
import {Alert, Box, Paper, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import ListAvatar from "./ListAvatar";

const AllManagers = () => {
    const {t} = useTranslation();

    const {managers} = useSelector(state => state.Common);
    const {isLoading} = useSelector(state => state.LayoutReducer);

    return (
        <Box className={"p-2"}>
            {!isLoading &&
           (managers.length > 0 ?
               <Paper className={"p-2"} sx={{backgroundColor: "background.paper"}}>
                   <Typography variant="h6"
                               component="h6"
                               fontSize={17}
                               sx={{
                                   textTransform: "uppercase",
                                   textAlign: "center",
                                   marginBottom: 1
                               }}>
                       {t('all-managers')}
                   </Typography>
                   <ListAvatar items={managers}/>
               </Paper> :
               <Alert severity="info" elevation={2}>{t('no-data-found')}</Alert>)
            }
        </Box>
    );
}

export default AllManagers;

