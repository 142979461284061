import React from "react"
import {useTranslation} from "react-i18next";
import TabWithIcon from "../../../components/Tabs/TabWithIcon";
import {ArchiveTwoTone, PostAddTwoTone} from "@mui/icons-material";
import CreateNew from "./CreateNew";
import Responses from "./Responses";
import {useDispatch} from "react-redux";
import config from "../../../config";
import {toast} from "react-toastify";
import {changeDemandResponses} from "../../../store/common/action";
import {changePageLoading} from "../../../store/layout/action";

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    fetch(config.urlOrigin + '/get-user-demands?userId=' + localStorage.getItem("bonusUserId"), {
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            authorization: localStorage.getItem("bonusOrgId"),
        }
    }).then(async resp => {
        const jsonData = await resp.json();
        if (resp.status === 200) {
            dispatch(changeDemandResponses(jsonData.data));

        } else {
            toast.error(t('server-error'));
            console.log(jsonData.message);
        }

    }).catch(err => {
        console.log(err);
        toast.error(t('connection-error'));

    }).finally(() => dispatch(changePageLoading(false)));

    return (
        <TabWithIcon
            items={[
                {label: t("create"), icon: <PostAddTwoTone/>, content: <CreateNew/>},
                {label: t("archive"), icon: <ArchiveTwoTone/>, content: <Responses/>},
            ]}
        />
    );
}

export default Index;