import React from "react";
import {Alert, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {BonusesCardArchiveNoActions} from "../../../components/card/bonusesCard";

const ArchiveBonuses = () => {
    const {t} = useTranslation();

    const {bonusesArchive} = useSelector(state => state.Common);

    return (
        <Box className="p-2">
            {bonusesArchive?.length ? bonusesArchive.map((item, index) => (
                    <BonusesCardArchiveNoActions item={item} key={index}/>
                )) :
                <Alert severity="info" elevation={2}>{t('no-data-found')}</Alert>}
        </Box>
    );
}

export default ArchiveBonuses;