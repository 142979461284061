import * as React from 'react';
import Typography from '@mui/material/Typography';
import i18n from "i18next";
import moment from "moment";
import {Box, Chip, IconButton, Paper} from "@mui/material";
import {AccessTimeOutlined, AllInclusiveSharp, ArchiveSharp, DeleteSharp} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export function SharesCardActive({item, deleteHandler, archiveHandler}) {
    const lang = i18n.language;
    const {t} = useTranslation();

    return (
        <Paper className="mb-2">
            <Box className={"p-2 pb-0"}>
                <Typography variant="h6" component="div">
                    {item["title_" + lang]}
                </Typography>
                <Typography variant="body2" sx={{my: 0.5}}>
                    {item["desc_" + lang]}
                </Typography>

                <Box>
                    <Typography variant="subtitle2" component="span">
                        {t("start-date")}:
                    </Typography>
                    <Typography variant="body2" component="span" sx={{marginLeft: "5px"}}>
                        {item.date_start}
                    </Typography>
                </Box>

                <Box>
                    <Typography variant="subtitle2" component="span">
                        {t("end-date")}:
                    </Typography>
                    <Typography variant="body2" component="span" sx={{marginLeft: "5px"}}>
                        {item.date_end || <AllInclusiveSharp color="inherit"/>}
                    </Typography>
                </Box>
            </Box>
            <Box className={"flex justify-between items-center ps-2"}>
                <Typography variant="body2">
                    <AccessTimeOutlined sx={{
                        fontSize: "15px",
                        marginBottom: 0.5
                    }}/> {moment(item['created_time']).format('DD.MM.YYYY HH:mm:ss')}
                </Typography>
                <Box>
                    <IconButton color={"error"} onClick={e => {
                        deleteHandler(item.id);
                    }}>
                        <DeleteSharp/>
                    </IconButton>
                    <IconButton color={"warning"} onClick={e => {
                        archiveHandler(item.id);
                    }}>
                        <ArchiveSharp/>
                    </IconButton>
                </Box>
            </Box>
        </Paper>
    );
}

export function SharesCardArchive({item, deleteHandler}) {
    const lang = i18n.language;
    const {t} = useTranslation();

    return (
        <Paper className="mb-2">
            <Box className={"p-2 pb-0"}>
                <Typography variant="h6" component="div">
                    {item["title_" + lang]}
                </Typography>
                <Typography variant="body2" sx={{my: 0.5}}>
                    {item["desc_" + lang]}
                </Typography>

                <Box>
                    <Typography variant="subtitle2" component="span">
                        {t("start-date")}:
                    </Typography>
                    <Typography variant="body2" component="span" sx={{marginLeft: "5px"}}>
                        {item.date_start}
                    </Typography>
                </Box>

                <Box>
                    <Typography variant="subtitle2" component="span">
                        {t("end-date")}:
                    </Typography>
                    <Typography variant="body2" component="span" sx={{marginLeft: "5px"}}>
                        {item.date_end || <AllInclusiveSharp color="inherit"/>}
                    </Typography>
                </Box>

                <Box>
                    <Typography variant="subtitle2" component="span">
                        {t("status")}:
                    </Typography>
                    <Typography variant="body2" component="span" sx={{marginLeft: "5px"}}>
                        <Chip label={t(item.who_archived ? "admin-archived" : 'deadlined')}
                              color="secondary"
                              variant="filled"
                              size={"small"}
                        />
                    </Typography>
                </Box>
            </Box>
            <Box className={"flex justify-between items-center ps-2"}>
                <Typography variant="body2">
                    <AccessTimeOutlined sx={{
                        fontSize: "15px",
                        marginBottom: 0.5
                    }}/> {moment(item['created_time']).format('DD.MM.YYYY HH:mm:ss')}
                </Typography>
                <Box>
                    <IconButton color={"error"} onClick={e => {
                        deleteHandler(item.id);
                    }}>
                        <DeleteSharp/>
                    </IconButton>
                </Box>
            </Box>
        </Paper>
    );
}


export function SharesCardActiveNoActions({item}) {
    const lang = i18n.language;
    const {t} = useTranslation();

    return (
        <Paper className="mb-2 p-2">
            <Typography variant="h6" component="div">
                {item["title_" + lang]}
            </Typography>
            <Typography variant="body2" sx={{my: 0.5}}>
                {item["desc_" + lang]}
            </Typography>

            <Box>
                <Typography variant="subtitle2" component="span">
                    {t("start-date")}:
                </Typography>
                <Typography variant="body2" component="span" sx={{marginLeft: "5px"}}>
                    {item.date_start}
                </Typography>
            </Box>

            <Box>
                <Typography variant="subtitle2" component="span">
                    {t("end-date")}:
                </Typography>
                <Typography variant="body2" component="span" sx={{marginLeft: "5px"}}>
                    {item.date_end || <AllInclusiveSharp color="inherit"/>}
                </Typography>
            </Box>
            <Box className={"mt-1.5"}>
                <Typography variant="body2">
                    <AccessTimeOutlined sx={{
                        fontSize: "15px",
                        marginBottom: 0.5
                    }}/> {moment(item['created_time']).format('DD.MM.YYYY HH:mm:ss')}
                </Typography>
            </Box>
        </Paper>
    );
}


export function SharesCardArchiveNoActions({item}) {
    const lang = i18n.language;
    const {t} = useTranslation();

    return (
        <Paper className="p-2 mb-2">
            <Typography variant="h6" component="div">
                {item["title_" + lang]}
            </Typography>
            <Typography variant="body2" sx={{my: 0.5}}>
                {item["desc_" + lang]}
            </Typography>

            <Box>
                <Typography variant="subtitle2" component="span">
                    {t("start-date")}:
                </Typography>
                <Typography variant="body2" component="span" sx={{marginLeft: "5px"}}>
                    {item.date_start}
                </Typography>
            </Box>

            <Box>
                <Typography variant="subtitle2" component="span">
                    {t("end-date")}:
                </Typography>
                <Typography variant="body2" component="span" sx={{marginLeft: "5px"}}>
                    {item.date_end || <AllInclusiveSharp color="inherit"/>}
                </Typography>
            </Box>

            <Box>
                <Typography variant="subtitle2" component="span">
                    {t("status")}:
                </Typography>
                <Typography variant="body2" component="span" sx={{marginLeft: "5px"}}>
                    <Chip label={t(item.who_archived ? "admin-archived" : 'deadlined')}
                          color="secondary"
                          variant="filled"
                          size={"small"}
                    />
                </Typography>
            </Box>
            <Box className={"mt-1.5"}>
                <Typography variant="body2">
                    <AccessTimeOutlined sx={{
                        fontSize: "15px",
                        marginBottom: 0.5
                    }}/> {moment(item['created_time']).format('DD.MM.YYYY HH:mm:ss')}
                </Typography>
            </Box>
        </Paper>
    );
}

