import React, {useEffect} from 'react';
import {Card, CardContent, Typography, Box,} from '@mui/material';
import {DeveloperBoard, LoopOutlined} from "@mui/icons-material";
import {CardExpiresField, CardHolderField, CardNumberField} from "../../../components/Fields/plasticCardFields";
import {useTranslation} from "react-i18next";
import {ButtonReset, ButtonSubmit} from "../../../components/buttons/buttons";
import Save2FillIcon from "remixicon-react/Save2FillIcon";
import {useDispatch, useSelector} from "react-redux";

import {useFormik} from "formik";
import * as Yup from "yup";
import config from "../../../config";
import {toast} from "react-toastify";
import {setUserData} from "../../../store/common/action";
import {formatCardNumber} from "../../../helpers/common";

const PlasticCard = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {user} = useSelector(state => state.Common);
    const [isFetching, setIsFetching] = React.useState(false);
    const [isReseted, setIsReseted] = React.useState(false);

    useEffect(() => {
        validation.setValues({
            cardNumber: user.card_number ? formatCardNumber(user.card_number) : '',
            cardHolder: user.card_holder || '',
            cardExpires: user.card_expires || ''
        });
    }, [user, isReseted]);

    const validation = useFormik({
        initialValues: {
            cardNumber: '',
            cardHolder: '',
            cardExpires: ''
        },
        validationSchema: Yup.object().shape({
            cardNumber: Yup.string().required().matches(/^\d{4}\s\d{4}\s\d{4}\s\d{4}$/),
            cardHolder: Yup.string().required(),
            cardExpires: Yup.string().required().matches(/^\d{2}\/\d{2}$/),
        }),
        onSubmit: values => {
            // console.log(values);
            setIsFetching(true);
            fetch(config.urlOrigin + '/update-user-card-data', {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem('bonusOrgId'),
                },
                body: JSON.stringify({
                    ...values,
                    userId: user.id,
                })

            }).then(async resp => {
                const jsonData = await resp.json();
                if (resp.status === 200) {
                    dispatch(setUserData({
                        ...user,
                        card_number: values.cardNumber,
                        card_expires: values.cardExpires,
                        card_holder: values.cardHolder
                    }));

                    toast.success(t('saved'));

                } else {
                    toast.error(t('server-error'));
                    console.log(jsonData.message);
                }

            }).catch(error => {
                console.log(error);
                toast.error(t('unknown-error'));

            }).finally(() => setIsFetching(false));
        }
    })

    return (
        <form onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}>
            <Card
                sx={{
                    height: 210,
                    borderRadius: '10px',
                    background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
                    color: '#fff',
                    padding: '10px',
                    position: 'relative',
                }}
            >
                <CardContent sx={{height: '100%'}}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <DeveloperBoard fontSize={"large"}/>
                        <Typography variant="body2">CARD</Typography>
                    </Box>
                    <CardNumberField
                        label={t('card-number')}
                        placeHolder={'xxxx xxxx xxxx xxxx'}
                        name={"cardNumber"}
                        value={validation.values.cardNumber}
                        error={validation.touched.cardNumber && validation.errors.cardNumber}
                        handler={validation.handleChange}/>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                            <CardHolderField
                                label={t('card-holder')}
                                name={"cardHolder"}
                                value={validation.values.cardHolder}
                                error={validation.touched.cardHolder && validation.errors.cardHolder}
                                handler={validation.handleChange}/>
                        </Box>
                        <Box>
                            <CardExpiresField
                                label={t('expires')}
                                placeHolder={'MM/YY'}
                                name={"cardExpires"}
                                value={validation.values.cardExpires}
                                error={validation.touched.cardExpires && validation.errors.cardExpires}
                                handler={validation.handleChange}
                            />
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <Box display="flex" gap={1} justifyContent={"end"} className={"mt-3"}>
                <ButtonReset
                    text={t('update')}
                    color={'info'}
                    startIcon={<LoopOutlined/>}
                    size={"medium"}
                    variant={"outlined"}
                    handler={() => {
                        validation.handleReset();
                        setIsReseted(state => !state);
                    }}
                />
                <ButtonSubmit
                    color={"success"}
                    text={t('save')}
                    isFetching={isFetching}
                    fullWidth={false}
                    startIcon={<Save2FillIcon/>}/>
            </Box>
        </form>
    );
};

export default PlasticCard;
