import {
    ApartmentTwoTone,
    ArchiveTwoTone,
    CardGiftcardTwoTone,
    DarkModeTwoTone, DashboardTwoTone, FeedTwoTone, MedicalServicesTwoTone,
    MonetizationOnTwoTone, NoteAddTwoTone, NoteAltTwoTone,
    PersonTwoTone, PinDropTwoTone,
    PriceChangeTwoTone, QrCodeScannerTwoTone, ReceiptLongTwoTone
} from "@mui/icons-material";

export const adminMenu = [
    {icon: <DashboardTwoTone/>, key: "dashboard", url: "/dashboard?role=1"},
    {icon: <ApartmentTwoTone/>, key: "organization", url: "/admin/organization"},
    {icon: <CardGiftcardTwoTone/>, key: "shares", url: "/admin/shares"},
    {icon: <MonetizationOnTwoTone/>, key: "bonuses", url: "/admin/bonuses"},
    {icon: <PriceChangeTwoTone/>, key: "service-price", url: "/admin/services"},
    {icon: <NoteAltTwoTone/>, key: "requirement", url: "/admin/demand"},
    {icon: <DarkModeTwoTone/>, key: "dark", url: ""},
    {icon: <PersonTwoTone/>, key: "profile", url: "/admin/profile"},
];

export const managerMenu = [
    {icon: <DashboardTwoTone/>, key: "dashboard", url: "/dashboard?role=2"},
    {icon: <CardGiftcardTwoTone/>, key: "shares", url: "/manager/shares"},
    {icon: <MonetizationOnTwoTone/>, key: "bonuses", url: "/manager/bonuses"},
    {icon: <PriceChangeTwoTone/>, key: "service-price", url: "/manager/services"},
    {icon: <DarkModeTwoTone/>, key: "dark", url: ""},
    {icon: <PersonTwoTone/>, key: "profile", url: "/manager/profile"},
    {icon: <QrCodeScannerTwoTone/>, key: "qr-code", url: "/manager/qr-code"},
];

export const doctorMenu = [
    {icon: <DashboardTwoTone/>, key: "dashboard", url: "/dashboard?role=3"},
    {icon: <CardGiftcardTwoTone/>, key: "shares", url: "/doctor/shares"},
    {icon: <MonetizationOnTwoTone/>, key: "bonuses", url: "/doctor/bonuses"},
    {icon: <MedicalServicesTwoTone/>, key: "services", url: "/doctor/services"},
    {icon: <FeedTwoTone/>, key: "report", url: "/doctor/report"},
    {icon: <PinDropTwoTone/>, key: "location", url: "/doctor/location"},
    {icon: <NoteAddTwoTone/>, key: "requirement", url: "/doctor/demand"},
    // {icon: <ArchiveTwoTone/>, key: "archive", url: "/doctor/archive"},
    {icon: <PersonTwoTone/>, key: "profile", url: "/doctor/profile"},
    {icon: <DarkModeTwoTone/>, key: "dark", url: ""},
    {icon: <ReceiptLongTwoTone/>, key: "instructions", url: "/doctor/instructions"},
];