import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    border: '1px solid #000',
    boxShadow: 24,
    p: 0,
};

export default function ModalImage({isOpen, handleClose, imagePath}) {

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img src={imagePath} alt={''} className={'w-100'}/>
                </Box>
            </Modal>
        </div>
    );
}
