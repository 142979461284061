import React from "react";
import {RequestsToRespondCard} from "../../../components/card/demandsCard";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/material";
import config from "../../../config";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {changeDemandResponses} from "../../../store/common/action";
import {AlertControlled} from "../../../components/alerts";

const Demands2Respond = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {demandsToResponse, demandResponses} = useSelector(state => state.Common);

    const [showAlert, setShowAlert] = React.useState(true);

    const messageHandler = async (id, text, status) => {
        return await fetch(config.urlOrigin + '/respond-user-demand', {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                authorization: localStorage.getItem("bonusOrgId"),
            },
            body: JSON.stringify({
                userId: localStorage.getItem("bonusUserId"),
                text: text,
                status: status,
                id: id
            })
        }).then(async resp => {
            const jsonData = await resp.json();
            if (resp.status === 200) {
                dispatch(changeDemandResponses([...demandResponses, jsonData.data]));

                return true;

            } else {
                toast.error(t('server-error'));
                console.log(jsonData.message);
                return false;
            }

        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));
            return false;
        });
    }

    return (
        <Box className="p-2">
            {showAlert &&
                <AlertControlled text={t('respond-info')}/>}
            {demandsToResponse.map((item, index) =>
                (<RequestsToRespondCard item={item} key={index} messageHandler={messageHandler}/>))}
        </Box>
    );
}

export default Demands2Respond;