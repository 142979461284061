import * as React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import {Box, Divider, ListItemButton} from '@mui/material';
import {AccessTimeOutlined, CheckBoxSharp, DisabledByDefaultSharp, PendingSharp} from "@mui/icons-material";
import moment from "moment";

const ListWIthStatus = ({items}) => {
    // console.log(items)
    return (
        <List sx={{width: '100%', height: "100%", overflow: "auto", p: 0}}>
            {items.map((item, index) => <React.Fragment key={index}>
                <ListItemButton
                    alignItems="center"
                    sx={{
                        py: 0,
                        gap: 1
                    }}
                    disableTouchRipple={true}
                    disableGutters={true}
                >
                    <ListItemAvatar sx={{
                        minWidth: 20
                    }}>
                        {item.manager_resp === 0 ? <PendingSharp color={"info"}/> :
                            item.manager_resp === 1 ?
                                <CheckBoxSharp color={"success"}/> :
                                <DisabledByDefaultSharp color={"error"}/>}
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography variant={"body2"} fontSize={16} lineHeight={1.2}>
                                {item['full_name']}
                            </Typography>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{display: 'inline'}}
                                    component="span"
                                    variant="body2"
                                    className='text-custom-black dark:text-gray-400 font-normal'
                                >
                                    {item['phone'] || '-'}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                    <Box sx={{textAlign: "end"}}>
                        <Typography variant={"body2"} component={"p"}>
                            <AccessTimeOutlined
                                sx={{
                                    fontSize: 15,
                                    marginBottom: '4px',
                                    marginRight: '2px'
                                }}
                                color={"inherit"}/>
                            {item.manager_resp_time ? moment(item.manager_resp_time).format('DD.MM.YYYY') : '-'}
                        </Typography>
                        <Typography variant={"body2"} component={"p"} color={"text.secondary"}>
                            {item.manager_resp_time ? moment(item.manager_resp_time).format('HH:mm:ss') : '-'}
                        </Typography>
                    </Box>
                </ListItemButton>
                <Divider/>
            </React.Fragment>)
            }
        </List>
    );
}

export default ListWIthStatus;
