import React from "react";
import {SharesCardArchive} from "../../../components/card/sharesCards";
import {Alert, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import config from "../../../config";
import {changeSharesArchive} from "../../../store/common/action";
import {toast} from "react-toastify";

const ArchiveShares = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {sharesArchive} = useSelector(state => state.Common);

    const handleDelete = (id) => {
        fetch(config.urlOrigin + '/delete-shares', {
            method: 'DELETE',
            headers: {
                "Content-type": "application/json",
                authorization: localStorage.getItem("bonusOrgId"),
            },
            body: JSON.stringify({
                id
            })
        }).then(async resp => {
            const data = await resp.json();
            if (resp.status === 200) {
                const newSharesArchive = [...sharesArchive].filter(item => item.id !== id);

                dispatch(changeSharesArchive(newSharesArchive));
                toast.success(t('deleted'));

            } else {
                console.log(data.message);
                toast.error(t('server-error'));
            }

        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));
        });
    }

    return (
        <Box className="p-2">
            {sharesArchive?.length ? sharesArchive.map((item, index) => (
                    <SharesCardArchive item={item} key={index} deleteHandler={handleDelete}/>
                )) :
                <Alert severity="info" elevation={2}>{t('no-data-found')}</Alert>}
        </Box>
    );
}

export default ArchiveShares;