export const USER = "USER";
export const DOCTOR_DATA = "DOCTOR_DATA";
export const PATIENTS = "PATIENTS";
export const SHARES_ACTIVE = "SHARES_ACTIVE";
export const SHARES_ARCHIVE = "SHARES_ARCHIVE";
export const BONUSES_ACTIVE = "BONUSES_ACTIVE";
export const BONUSES_ARCHIVE = "BONUSES_ARCHIVE";
export const DEMAND_RESPONSES = "DEMAND_RESPONSES";
export const DEMANDS_TO_RESPOND = "DEMANDS_TO_RESPOND";
export const MANAGERS = "MANAGERS";
export const DOCTORS_NO_MANAGER = "DOCTORS_NO_MANAGER";
export const DOCTOR_PATIENTS = "DOCTOR_PATIENTS";
export const ADD_DOCTOR_PATIENT = "ADD_DOCTOR_PATIENT";
export const DOCTORS_MANAGER_CONNECTED = "DOCTORS_MANAGER_CONNECTED";
export const DOCTORS_ADMIN_CONNECTED = "DOCTORS_ADMIN_CONNECTED";
export const UNPAIND_PATIENTS = "UNPAIND_PATIENTS";
export const PAYMENTS = "PAYMENTS";
export const ADD_PAYMENTS = "ADD_PAYMENTS";

export const AVATAR_LOADING = "AVATAR_LOADING";