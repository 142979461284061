import React, {useEffect, useState} from 'react';
import {Menu, MenuItem} from '@mui/material';

import UZ from "../../assets/images/flags/uz.svg";
import RU from "../../assets/images/flags/ru.svg";
import EN from "../../assets/images/flags/us.svg";

import i18n from "../../i18n";
import config from "../../config";
import {useDispatch, useSelector} from "react-redux";
import {changeLanguage} from "../../store/layout/action";

const LanguageDropdownWithoutName = () => {
    const dispatch = useDispatch();
    const {language} = useSelector(state => state.LayoutReducer);

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const toggleDropdown = (event) => {
        setOpen(!open);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (lang) => {
        dispatch(changeLanguage(lang));
        i18n.changeLanguage(lang);
        setOpen(false);
        setAnchorEl(null);

        localStorage.setItem('bonusLang', lang);

        if (localStorage.getItem('bonusUserId')) {
            fetch(config.urlOrigin + '/update-user-data', {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem('bonusOrgId')
                },
                body: JSON.stringify({
                    userId: +localStorage.getItem('bonusUserId'),
                    data: {
                        lang: lang
                    }
                })
            }).catch(err => {
                console.log("Error updating user language", err);
            });
        }
    };

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('bonusLang') || 'oz');
    }, []);

    return (
        <div className=''>
            <span
                aria-controls="simple-menu"
                aria-haspopup="true"
                className='pe-2 block'
                onClick={toggleDropdown}
            >
                {
                    language === 'uz' || language === 'oz' ?
                        <img className="w-6 h-6 rounded-full mr-1" src={UZ} alt="UZ"/> :
                        language === 'ru' ? <img className="w-6 h-6 rounded-full mr-1" src={RU} alt="RU"/> :
                            <img className="w-6 h-6 rounded-full mr-1" src={EN} alt="EN"/>
                }
            </span>
            <Menu
                id="simple-menu"
                open={open}
                onClose={toggleDropdown}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

            >
                <MenuItem sx={{paddingTop: 0, paddingBottom: 0}} onClick={() => {
                    handleClose('oz')
                }}>
                    <img className="w-6 h-6 rounded-full mr-1" src={UZ} alt="UZ"/> O'zbekcha
                </MenuItem>
                <MenuItem sx={{paddingTop: 0, paddingBottom: 0}} onClick={() => {
                    handleClose('ru')
                }}>
                    <img className="w-6 h-6 rounded-full mr-1" src={RU} alt="RU"/> Русский
                </MenuItem> <MenuItem sx={{paddingTop: 0, paddingBottom: 0}} onClick={() => {
                handleClose('uz')
            }}>
                <img className="w-6 h-6 rounded-full mr-1" src={UZ} alt="RU"/> Ўзбекча
            </MenuItem>
                <MenuItem sx={{paddingTop: 0, paddingBottom: 0}} onClick={() => {
                    handleClose('en')
                }}>
                    <img className="w-6 h-6 rounded-full mr-1" src={EN} alt="EN"/> English
                </MenuItem>
            </Menu>
        </div>
    );
};

export default LanguageDropdownWithoutName;
