import React from 'react';
import './App.css';
import Routes from "./routes";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CssBaseline, ThemeProvider, createTheme} from '@mui/material';
import {useSelector} from 'react-redux';

function App() {
    const mode = useSelector(state => state.LayoutReducer.mode);
    const themeLight = createTheme({
        palette: {
            mode: "light",
            success: {
                main: '#0ca843',
                contrastText: '#ffffff',
            },
            error: {
                main: '#f25546',
                contrastText: '#ffffff',
            },
            background: {
                default: '#ececec', // Background color for the entire app
                paper: '#fafafa', // Background color for paper components,
                table:"#e0e0e0"
            },
            warning: {
                main: '#d9d912',
                contrastText: "#ffffff",

            },
            info: {
                main: "#0288d1",
                contrastText: '#ffffff',
            },
            primary: {
                main: "#1976d2",
                contrastText: "#ffffff"
            }
        },
        components: {
            MuiAlert: {
                styleOverrides: {
                    filledWarning: {
                        color: "#ffffff"
                    }
                }
            }
        }
    });
    const themeDark = createTheme({
        palette: {
            mode: 'dark',
            success: {
                main: '#0ca843',
                contrastText: '#ffffff',
            },
            error: {
                main: '#f25546',
                contrastText: '#ffffff',
            },
            background: {
                default: '#333333',
                paper: "#414141",
                table:"#3d3d3d"
            },
            warning: {
                main: '#f7f70c',
                contrastText: "#ffffff"
            },
            info: {
                main: "#0288d1",
                contrastText: '#ffffff',
            },
            primary: {
                main: "#1976d2",
                contrastText: "#ffffff"
            }
        }
    });

    return (
        <React.Fragment>
            <ToastContainer theme={mode}
                            position={"top-right"}
                            newestOnTop={true}
                            pauseOnHover={true}
                            autoClose={2000}
                // draggable={"touch"}
            />
            <ThemeProvider theme={mode === 'light' ? themeLight : themeDark}>
                <CssBaseline/>
                <Routes/>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default App;