import {Avatar, Box, Paper, Typography} from "@mui/material";
import FileUploadButton from "../../../components/Fields/FileUpload";
import TextAreaOutlined from "../../../components/Fields/textAreas";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AlertControlled} from "../../../components/alerts";
import {formatBytes, formatCardNumber} from "../../../helpers/common";
import {ContentCopySharp} from "@mui/icons-material";
import {ToolipClickIconBtn} from "../../../components/Tooltips";
import ListWithCheckbox from "../../../components/Lists/ListWithCheckbox";
import {addPayments, changeUnpaidPatients} from "../../../store/common/action";
import {useFormik} from "formik";
import * as Yup from "yup";
import defaultPdf from "../../../assets/images/pdf.png";
import ModalImage from "../../../components/Modals/ModalImage";
import {ButtonSubmit} from "../../../components/buttons/buttons";
import Save3FillIcon from "remixicon-react/Save3FillIcon";
import {toast} from "react-toastify";
import config from "../../../config";

const NewPayment = ({id}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {doctorData, unpaidPatients} = useSelector(state => state.Common);
    const {isLoading} = useSelector(state => state.LayoutReducer);

    const [file, setFile] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const fileHanlder = (data) => {
        setFile(data);
    }

    const copyHandler = () => {
        window.navigator.clipboard.writeText(doctorData.card_number);
    }

    const chekboxHandler = (event, id) => {
        const newState = [...unpaidPatients];
        const index = newState.findIndex((item) => item.id === id);
        newState[index].checked = event.target.checked;

        dispatch(changeUnpaidPatients(newState));
    }

    const validation = useFormik({
        initialValues: {
            description: ""
        },
        validationSchema: Yup.object().shape({
            description: Yup.string().required()
        }),
        onSubmit: values => {
            const selectedUsers = unpaidPatients.reduce((acc, current) => {
                if (current.checked) {
                    acc.push(current.id);
                }
                return acc;
            }, []);

            if (selectedUsers.length === 0) {
                toast.warning(t('select-one-client'));

            } else if (!file.base64) {
                toast.warning(t('upload-check-warn'));

            } else {
                fetch(config.urlOrigin + '/create-new-payment', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: localStorage.getItem('bonusOrgId')
                    },
                    body: JSON.stringify({
                        doctorId: id,
                        id: +localStorage.getItem('bonusUserId'),
                        patients: selectedUsers,
                        ...values,
                        file
                    })
                }).then(async resp => {
                    const jsonData = await resp.json();
                    if (resp.status === 200) {
                        toast.success(t('saved'));

                        const newState = [...unpaidPatients].filter((item) => !selectedUsers.includes(item.id));
                        dispatch(changeUnpaidPatients(newState));
                        setFile({});
                        validation.handleReset();

                        dispatch(addPayments(jsonData.data));

                    } else {
                        console.log(jsonData.message);
                        toast.error(t('server-error'));
                    }
                }).catch(err => {
                    console.log(err);
                    toast.error(t('connection-error'));
                });
            }
        }
    });

    return (
        <Box className={"p-2"}>
            <Paper className={"p-2 mb-2"}>
                <Typography
                    variant={"h6"}
                    sx={{
                        textAlign: "center",
                        textTransform: "capitalize",
                        fontSize: 17
                    }}
                >
                    {t('unpaid-patients')}
                </Typography>

                {
                    !isLoading && (
                        unpaidPatients.length ?
                            <Box
                                className={"overflow-y-scroll -mr-2"}
                                sx={{
                                    maxHeight: '50vh'
                                }}>
                                <ListWithCheckbox
                                    items={unpaidPatients}
                                    handlerChange={chekboxHandler}
                                />
                            </Box> :
                            <Box className={"mt-2"}>
                                <AlertControlled
                                    elevation={0}
                                    severity={"info"}
                                    text={t('no-data-found')}
                                />
                            </Box>
                    )
                }
            </Paper>
            <Paper className={"p-2"}>
                {
                    !isLoading && (
                        doctorData.card_number ?
                            <Box className={"mb-2"}>
                                <Box display={"flex"} gap={1} justifyContent={"space-between"}>
                                    <Typography variant={"subtitle2"} component={"p"}
                                                fontSize={14}>{t('card-number')}:</Typography>
                                    <Box className={"inline-flex gap-1 items-center"}>
                                        <Typography variant={"body2"} component={"p"} fontSize={14}>
                                            {formatCardNumber(doctorData.card_number)}
                                        </Typography>
                                        <ToolipClickIconBtn
                                            size={"small"}
                                            icon={<ContentCopySharp sx={{
                                                fontSize: 15
                                            }}/>}
                                            handler={copyHandler}
                                            title={t('copied')}
                                        />
                                    </Box>
                                </Box>

                                <Box className={"mb-1"} display={"flex"} gap={1} justifyContent={"space-between"}>
                                    <Typography variant={"subtitle2"} component={"p"}
                                                fontSize={14}>{t('card-holder')}:</Typography>
                                    <Typography variant={"body2"} component={"p"} fontSize={14}>
                                        {doctorData.card_holder || '-'}
                                    </Typography>
                                </Box>

                                <Box className={"mb-1"} display={"flex"} gap={1} justifyContent={"space-between"}>
                                    <Typography variant={"subtitle2"} component={"p"}
                                                fontSize={14}>{t('expires')}:</Typography>
                                    <Typography variant={"body2"} component={"p"} fontSize={14}>
                                        {doctorData.card_expires || '-'}
                                    </Typography>
                                </Box>
                                <Box display={"flex"} gap={1} justifyContent={"space-between"}>
                                    <Typography variant={"subtitle2"} component={"p"}
                                                fontSize={14}>{t('number-of-customers')}:</Typography>
                                    <Typography variant={"body2"} component={"p"} fontSize={14}>
                                        {unpaidPatients.filter(item => item.checked).length}
                                    </Typography>
                                </Box>
                            </Box>
                            :
                            <AlertControlled
                                text={t('no-card-data')}
                                severity={'warning'}
                                elevation={0}
                                variant={"outlined"}
                            />
                    )
                }

                <Box className={"justify-end gap-2 flex mb-2"}>
                    <FileUploadButton
                        text={t(file.base64 ? 'update-check' : 'upload-check')}
                        color={"secondary"}
                        variant={"outlined"}
                        size={"small"}
                        fileHandler={fileHanlder}
                    />
                </Box>
                {
                    file.base64 &&
                    <Box className={"mb-2 flex gap-2"}>
                        <Avatar
                            src={file.type.startsWith('image') ? file.base64 : defaultPdf}
                            alt={""}
                            variant={"square"}
                            sx={{
                                borderRadius: 1
                            }}
                            onClick={() => {
                                if (file.type.startsWith('image')) {
                                    setIsModalOpen(true);
                                }
                            }}
                        />
                        <Box className={"flex-grow"}>
                            <Typography fontSize={15}>
                                {file.name}
                            </Typography>
                            <Typography fontSize={13} color={"textSecondary"}>
                                {formatBytes(file.size)}
                            </Typography>
                        </Box>
                    </Box>
                }
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <TextAreaOutlined
                        name={"description"}
                        value={validation.values.description}
                        error={validation.touched.description && validation.errors.description}
                        label={t('description')}
                        placeholder={t('enter-desc')}
                        rows={2}
                        handler={validation.handleChange}
                    />
                    <ButtonSubmit
                        variant={"contained"}
                        text={t('save')}
                        startIcon={<Save3FillIcon/>}
                        color={"success"}
                        fullWidth={true}
                        isFetching={isFetching}
                        size={"small"}
                        sx={{
                            mt: 1.5
                        }}
                    />
                </form>
            </Paper>
            <ModalImage
                imagePath={file.base64}
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
            />
        </Box>
    )
}

export default NewPayment