import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

import DashboardAdmin from "../admin/dashboard";
import DashboardDoctor from "../doctors/dashboard";
import DashboardManager from "../manager/dashboard";
import i18n from "../../i18n";
import {useDispatch} from "react-redux";
import {changeLanguage} from "../../store/layout/action";

const Index = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    useEffect(() => {
        localStorage.setItem("backUrls", JSON.stringify([location.pathname + location.search]));

        if (query.get("org_id")) {
            localStorage.setItem('bonusOrgId', query.get("org_id"));
            localStorage.setItem('bonusUserId', query.get("user_id"));

            localStorage.setItem('bonusLang', query.get("lang"));
            i18n.changeLanguage(query.get("lang"));
            dispatch(changeLanguage(query.get("lang")));
        }
    }, []);

    return (
        <React.Fragment>
            {
                query.get('role') === '1' ?
                    <DashboardAdmin/> :
                    query.get('role') === '2' ?
                        <DashboardManager/> :
                        <DashboardDoctor/>
            }
        </React.Fragment>
    );
}

export default Index;
