import Authentification from '../pages/authentification';
import Dashboard from "../pages/dashboard";
import Prices from "../pages/prices";

// ADMIN
import AdminShares from "../pages/admin/shares";
import AdminBonuses from "../pages/admin/bonuses";
import AdminProfile from "../pages/admin/profile";
import AdminDemand from "../pages/admin/demand";
import AdminOrganization from "../pages/admin/Organization";
import ManagerDoctors from "../pages/admin/managerDoctors";
import AdminDoctorsPayment from "../pages/admin/payments";
// MANAGER
import QRcode from "../pages/manager/QRcode";
import UserRequests from "../pages/manager/userRequests";
import ManagerProfile from "../pages/manager/profile";
import ManagerShares from "../pages/manager/shares";
import ManagerBonuses from "../pages/manager/bonuses";

//  DOCTORS
import DoctorProfile from '../pages/doctors/profile';
import DoctorIntructions from '../pages/doctors/Instructions';
import DoctorLocation from '../pages/doctors/location';
import DoctorShares from '../pages/doctors/shares';
import DoctorBonuses from '../pages/doctors/bonuses';
import DoctorDemand from '../pages/doctors/demand';
import DoctorReport from '../pages/doctors/report';

const publicRoutes = [
    {path: '/sign-up', component: <Authentification/>},
];

const authenticatedRoutes = [
    {path: '/', component: <Dashboard/>},
    {path: '/dashboard', component: <Dashboard/>},

    {path: '/doctor/profile', component: <DoctorProfile/>},
    {path: '/doctor/instructions', component: <DoctorIntructions/>},
    {path: '/doctor/location', component: <DoctorLocation/>},
    {path: '/doctor/shares', component: <DoctorShares/>},
    {path: '/doctor/bonuses', component: <DoctorBonuses/>},
    {path: '/doctor/demand', component: <DoctorDemand/>},
    {path: '/doctor/services', component: <Prices/>},
    {path: '/doctor/report', component: <DoctorReport/>},

    //    ADMIN ROUTES
    {path: '/admin/profile', component: <AdminProfile/>},
    {path: '/admin/shares', component: <AdminShares/>},
    {path: '/admin/bonuses', component: <AdminBonuses/>},
    {path: '/admin/demand', component: <AdminDemand/>},
    {path: '/admin/organization', component: <AdminOrganization/>},
    {path: '/admin/services', component: <Prices/>},

    //  MANAGER ROUTES
    {path: '/manager/qr-code', component: <QRcode/>},
    {path: '/manager/profile', component: <ManagerProfile/>},
    {path: '/manager/shares', component: <ManagerShares/>},
    {path: '/manager/bonuses', component: <ManagerBonuses/>},
    {path: '/manager/services', component: <Prices/>},
];

const authentificatedRoutesBack = [
    {path: '/manager/doctor-requests/:id', component: <UserRequests/>},
    {path: '/admin/manager-doctors/:id', component: <ManagerDoctors/>},
    {path: '/admin/manager-doctors/payments/:id', component: <AdminDoctorsPayment/>},
];

export {publicRoutes, authenticatedRoutes, authentificatedRoutesBack};
