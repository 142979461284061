import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Alert, Box} from "@mui/material";
import {ResponsesCard} from "../../../components/card/demandsCard";
import config from "../../../config";
import {toast} from "react-toastify";
import {changeDemandsToRespond} from "../../../store/common/action";

const Responses = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {demandResponses, demandsToResponse} = useSelector(state => state.Common);

    const handleDelete = async (id) => {
        return await fetch(config.urlOrigin + '/delete-user-demand-response?id=' + id, {
            method: 'DELETE',
            headers: {
                "Content-type": "application/json",
                authorization: localStorage.getItem("bonusOrgId"),
            }
        }).then(async resp => {
            const jsonData = await resp.json();
            if (resp.status === 200) {
                toast.success(t('deleted'));

                dispatch(changeDemandsToRespond([...demandsToResponse, jsonData.data]));

                return true;

            } else {
                toast.error(t('server-error'));
                console.log(jsonData.message);
                return false;
            }

        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));
            return false;
        });
    }

    return (
        <Box className="p-2">
            {demandResponses.length > 0 ?
                demandResponses.map((item, index) => (
                    <ResponsesCard key={index} item={item} deleteHandler={handleDelete}/>
                )) : <Alert elevation={2} severity={"info"}>{t('no-data-found')}</Alert>}
        </Box>
    );

}

export default Responses;
