import React, {useEffect} from 'react';
import Drawer from '@mui/material/Drawer';
import {
    Avatar,
    Box, Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Switch,
    Typography
} from '@mui/material';
import {
    ArrowBackIosNewSharp, Menu
} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';

import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import userPhoto from "../assets/images/user-dummy-img.jpg";
import {changeMode} from '../store/layout/action';
import {adminMenu, doctorMenu, managerMenu} from "../constants/layoutMenuData";
import config from "../config";
import ModalImage from "../components/Modals/ModalImage";
import {setUserData} from "../store/common/action";

function OffCanvasMenu() {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const query = new URLSearchParams(useLocation().search);

    const [open, setOpen] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const mode = useSelector(state => state.LayoutReducer.mode);
    const {user} = useSelector(state => state.Common);

    let role = sessionStorage.getItem('role');
    if (query.get('role')) {
        sessionStorage.setItem('role', query.get('role'));
        role = sessionStorage.getItem('role');
    }

    document.querySelector('html').classList.add('light');
    const changemode = () => {
        if (document.querySelector('html').classList.contains('dark')) {
            document.querySelector('html').classList.add('light');
            document.querySelector('html').classList.remove('dark');

        } else {
            document.querySelector('html').classList.add('dark');
            document.querySelector('html').classList.remove('light');
        }
        dispatch(changeMode(mode === 'light' ? 'dark' : 'light'));
        localStorage.setItem('mode', mode === 'light' ? 'dark' : 'light');
    }
    //
    const toggleDrawer = (isOpen) => () => {
        setOpen(isOpen);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    useEffect(() => {
        fetch(config.urlOrigin + '/get-user-data?id=' + localStorage.getItem('bonusUserId'), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem('bonusOrgId')
            }
        }).then(async resp => {
            const jsonData = await resp.json();

            if (resp.status === 200) {
                dispatch(setUserData(jsonData.data));

            } else {
                console.log(jsonData.message);
                // toast.error(t('server-error'));
            }
        }).catch(err => {
            console.log(err);
            // toast.error(t('connection-error'));
        });
    }, []);

    return (
        <React.Fragment>
            <IconButton onClick={toggleDrawer(true)} className='p-0'>
                <Menu sx={{fontSize: 30}} className={"text-dark dark:text-gray-100"}/>
            </IconButton>
            <Drawer
                anchor="left"
                open={open}
                onClose={toggleDrawer(false)}
                PaperProps={{
                    style: {
                        width: "auto",
                        maxWidth: 300
                    }
                }}
            >
                <Box className="flex justify-between items-center bg-custom-blue pr-1 ps-2">
                    <div className='flex items-center gap-2 py-5 ps-3'>
                        <Avatar
                            src={user.image_path ? config.urlOrigin + user.image_path : userPhoto}
                            sx={{
                                height: 48,
                                width: 48
                            }}
                            alt=''
                            onClick={() => {
                                toggleModal();
                            }}/>
                        <div className={"text-gray-100"}>
                            <Typography variant="body1"
                                        className='overflow-hidden text-nowrap text-ellipsis'
                                        sx={{fontSize: 17, width: 180}}>
                                {user.full_name}
                            </Typography>
                            <Typography variant={"body2"}>id: {user.id}</Typography>
                        </div>
                    </div>
                    <IconButton onClick={toggleDrawer()}>
                        <ArrowBackIosNewSharp className={"text-gray-100"}/>
                    </IconButton>
                    <ModalImage isOpen={isModalOpen}
                                handleClose={toggleModal}
                                imagePath={user.image_path ? config.urlOrigin +  user.image_path : userPhoto}/>
                </Box>
                <Box
                    className='h-full'
                    sx={{backgroundColor: "background.paper"}}
                >
                    <List
                        component="nav"
                        aria-label="main mailbox folders"
                        className='dark:text-gray-300'
                        sx={{paddingBottom: 1}}
                    >
                        {(role === '1' ? adminMenu : role === '2' ? managerMenu : doctorMenu).map((item, index) => {
                            if (index === 0)
                                return <React.Fragment key={index}>
                                    {/*<Divider/>*/}
                                    <ListItemButton
                                        sx={{paddingBottom: "4px", paddingTop: "6px"}}
                                        href={item.url}
                                    >
                                        <ListItemIcon className={"ms-2 dark:text-gray-300"}>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={t(item.key)}/>
                                    </ListItemButton>
                                    <Divider sx={{margin: '8px 0'}}/>
                                </React.Fragment>
                            else if (item.key === 'dark')
                                return <React.Fragment key={index}>
                                    {/*<Divider/>*/}
                                    <ListItemButton sx={{paddingBottom: "4px", paddingTop: "4px"}}>
                                        <ListItemIcon className={"ms-2 dark:text-gray-300"}>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={t(item.key)}/>
                                        <Switch color="default" checked={mode === 'dark'} onChange={changemode}/>
                                    </ListItemButton>
                                </React.Fragment>
                            else
                                return <React.Fragment key={index}>
                                    {/*<Divider/>*/}
                                    <ListItemButton
                                        sx={{paddingBottom: "4px", paddingTop: "4px"}}
                                        href={item.url}
                                    >
                                        <ListItemIcon className={"ms-2 dark:text-gray-300"}>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={t(item.key)}/>
                                    </ListItemButton>
                                </React.Fragment>
                        })}
                        {/*<Divider/>*/}
                    </List>
                </Box>

                <div className='text-center fixed bottom-2 left-7'>
                    <p>version - <span className='text-sm'>1.2.11</span></p>
                    <p className="mb-0 text-center tracking-wide text-[12px]">
                        © 2024 Bonus Bot.
                        <span className="ms-2">Created by SeroSoft</span>
                    </p>
                </div>
            </Drawer>
        </React.Fragment>
    )
        ;
}

export default OffCanvasMenu;
