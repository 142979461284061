import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {TextFielSearch} from "../Fields/textFields";
import cyrillicToTranslit from "cyrillic-to-translit-js";

export default function TableFixedHeader({
                                             rows,
                                             startRowsPerPage = 12
                                         }) {

    const theme = useTheme();
    const {t} = useTranslation();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(startRowsPerPage);
    const [searchText, setSearchText] = React.useState('');
    const [filtredRows, setFilteredRows] = React.useState(rows);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeText = (event) => {
        const text = event.target.value;
        setSearchText(text);
        setFilteredRows(state => {
            return rows.filter(item => item.title.toLowerCase().includes(cyrillicToTranslit().reverse(text.toLowerCase())))
        });
    }

    return (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <TableContainer sx={{maxHeight: "80vh"}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow sx={{textTransform: "uppercase"}}>
                            <TableCell
                                key={'title'}
                                align={'center'}
                                style={{
                                    minWidth: 100,
                                    padding: 5,
                                    textWrap: 'wrap',
                                    backgroundColor: theme.palette.background.table
                                }}
                            >
                                <TextFielSearch
                                    value={searchText}
                                    handler={handleChangeText}
                                    size={"small"}
                                    placeholder={t('search')}
                                />
                            </TableCell>
                            <TableCell
                                key={'price_one'}
                                align={"center"}
                                style={{
                                    minWidth: 100,
                                    padding: 5,
                                    textWrap: 'wrap',
                                    backgroundColor: theme.palette.background.table
                                }}
                            >
                                {t('price')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filtredRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    <TableCell
                                        key={"title"}
                                        align={"center"}
                                        style={{
                                            padding: 5,
                                            textWrap: 'wrap',
                                        }}
                                    >
                                        {row.title}
                                    </TableCell>
                                    <TableCell
                                        key={"name"}
                                        align={"center"}
                                        style={{
                                            padding: 5,
                                            textWrap: 'wrap',
                                        }}
                                    >
                                        {row.price_one}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[startRowsPerPage, 50, 100]}
                component="div"
                count={filtredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={""}
                showFirstButton={true}
                showLastButton={true}
                sx={{
                    '.css-16c50h-MuiInputBase-root-MuiTablePagination-select': {
                        marginRight: 1
                    },
                    '.MuiTablePagination-actions': {
                        marginLeft: 1,
                    },
                    '.MuiTablePagination-toolbar': {
                        paddingLeft: 0
                    }
                }}
            />
        </Paper>
    );
}
