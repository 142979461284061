import {Avatar, Box, IconButton, Typography, useTheme,} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {ArrowBackIosNewSharp} from "@mui/icons-material";
import {useSelector} from "react-redux";
import config from "../config";

import defaultUser from "../assets/images/user-dummy-img.jpg";
import ModalImage from "../components/Modals/ModalImage";

const HeaderWithBack = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {doctorData} = useSelector(state => state.Common);

    const [isOpen, setIsOpen] = React.useState(false);

    const toggle = () => {
        setIsOpen(state => !state);
    }

    return (
        <React.Fragment>
            <Box
                className='flex justify-between items-center gap-2 ps-2 pe-4 py-1 fixed top-0 right-0 left-0 shadow-md'
                sx={{
                    backgroundColor: theme.palette.background.default,
                }}
                zIndex={1000}>
                <IconButton onClick={()=>{window.history.back()}}>
                    <ArrowBackIosNewSharp/>
                </IconButton>
                <Typography sx={{lineHeight: 1.3}}>
                    {doctorData?.full_name}
                </Typography>
                <Avatar src={doctorData?.image_path ? config.urlOrigin + doctorData.image_path : defaultUser}
                        className={"border-2 border-gray-200 dark:border-gray-600"}
                        sx={{width: 43, height: 43}}
                        alt='avatar'
                        onClick={toggle}
                />
            </Box>
            <ModalImage isOpen={isOpen}
                        handleClose={toggle}
                        imagePath={doctorData?.image_path ? config.urlOrigin + doctorData.image_path : defaultUser}/>
        </React.Fragment>
    );
}
export default HeaderWithBack;