import React, {useEffect, useState} from "react";
import config from "../../../config";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setDoctorData, setPatientsData} from "../../../store/common/action";
import TabWithoutIcon from "../../../components/Tabs/TabWithoutIcon";
import moment from "moment";
import ListOfTodaySent from "./ListOfTodaySent";
import ListOfLast30DaysSent from "./ListOfLast30DaysSent";
import {changePageLoading} from "../../../store/layout/action";

const Index = () => {
    const {id} = useParams();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [todaysPatients, setTodaysPatients] = useState([]);
    const [last30daysPatients, setLast30daysPatients] = useState({});

    useEffect(() => {
        try {
            fetch(config.urlOrigin + '/get-user-data?id=' + id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem('bonusOrgId')
                }
            }).then(async (res) => {
                const data = await res.json();

                if (res.status === 500) {
                    toast.error(t('t-server-error'));
                    console.log(data.message);

                } else {
                    dispatch(setDoctorData(data.data));
                }
            }).catch(error => new Error(error))
                .finally(() => dispatch(changePageLoading(false)));

            const currDateISO = moment(new Date()).format('YYYY-MM-DD');
            const dayBefore30Days = new Date();
            dayBefore30Days.setDate(dayBefore30Days.getDate() - 30);

            fetch(config.urlOrigin + '/get-patients-doctor-sent?id=' + id + '&start_date=' +
                moment(dayBefore30Days).format('YYYY-MM-DD') + '&end_date=' + currDateISO, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: localStorage.getItem('bonusOrgId')
                    }
                }
            ).then(async resp => {
                const data = await resp.json();
                if (resp.status === 500) {
                    toast.error(t('t-server-error'));
                    console.log(data.message);

                } else {
                    dispatch(setPatientsData(data.data));

                    const obj = {};
                    data.data.forEach(item => {
                        const dateISO = moment(new Date(item.created_time)).format('YYYY-MM-DD');

                        if (dateISO in obj)
                            obj[dateISO].push(item);
                        else
                            obj[dateISO] = [item];
                    });

                    if (currDateISO in obj) {
                        setTodaysPatients(obj[currDateISO]);
                        delete obj[currDateISO];
                    }

                    setLast30daysPatients(obj);
                }
            }).catch(error => new Error(error));

        } catch (err) {
            console.log(err);
            toast.error(t('connection-error'));
        }
    }, []);

    return (
        <React.Fragment>
            <TabWithoutIcon items={[
                {label: t('todays'), content: <ListOfTodaySent items={todaysPatients}/>},
                {label: t('last-30-days'), content: <ListOfLast30DaysSent items={last30daysPatients}/>},
            ]}/>
        </React.Fragment>
    );
}

export default Index;