import React, {useEffect, useState} from 'react';
import {PhoneNumberField, TextAreaIcon, TextFieldOutlinedIcon} from "../../components/Fields/textFields";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Alert, CircularProgress, Collapse, Divider, IconButton, Paper, Typography} from "@mui/material";
import {AccountBalanceSharp, BusinessCenterSharp, DarkMode, LightMode, Person} from "@mui/icons-material";
import {RowRadioButtonsGroup} from "../../components/Radios/radioGroups";
import {ButtonSubmit} from "../../components/buttons/buttons";
import config from "../../config";
import CheckFillIcon from "remixicon-react/CheckFillIcon";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {changeMode, changePageLoading} from "../../store/layout/action";
import {useTranslation} from "react-i18next";
import SaveFillIcon from "remixicon-react/SaveFillIcon";
import {useLocation} from "react-router-dom";
import i18n from "../../i18n";
import LanguageDropdownWithoutName from "../../components/common/LanguageDropdownWithoutName";
import Box from "@mui/material/Box";
import {AlertControlled} from "../../components/alerts";

const Index = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const query = new URLSearchParams(useLocation().search);

    const [isFetching, setIsFetching] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [isRejected, setIsRejected] = useState(false);

    const {mode, isLoading} = useSelector(state => state.LayoutReducer);

    const changemode = () => {
        if (document.querySelector('html').classList.contains('dark')) {
            document.querySelector('html').classList.add('light');
            document.querySelector('html').classList.remove('dark');

        } else {
            document.querySelector('html').classList.add('dark');
            document.querySelector('html').classList.remove('light');
        }
        dispatch(changeMode(mode === 'light' ? 'dark' : 'light'));
        localStorage.setItem('BONUS_MODE', mode === 'light' ? 'dark' : 'light');
    }
    document.querySelector('html').classList.add(mode);

    useEffect(() => {
        localStorage.setItem('bonusLang', query.get('lang'));
        i18n.changeLanguage(query.get('lang'));

        fetch(config.urlOrigin + '/check-user-pending?id=' + query.get('chat_id'), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                authorization: query.get('org_id')
            }
        }).then(async resp => {
            const jsonData = await resp.json();

            if (resp.status === 200) {
                setIsRejected(jsonData.isRejected);
                setIsPending(jsonData.isPending);

            } else {
                console.log(jsonData.message);
            }

        }).catch(error => {
            console.log(error);
        }).finally(() => dispatch(changePageLoading(false)));

    }, []);

    const validation = useFormik({
        initialValues: {
            fullName: "",
            speciality: "",
            workplace: "",
            phoneNumber: "",
            gender: '1'
        },
        validationSchema: Yup.object().shape({
            fullName: Yup.string().required(t('required-field')),
            speciality: Yup.string().required(t('required-field')),
            workplace: Yup.string().required(t('required-field')),
            phoneNumber: Yup.string().required(t('required-field')).matches(/^\(\d{2}\) \d{3}-\d{2}-\d{2}$/, t('phone-invalid')),
            gender: Yup.string().required(t('required-field'))
        }),
        onSubmit: values => {
            // console.log("values", values);
            setIsFetching(true);
            fetch(config.urlOrigin + '/create-user', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    authorization: query.get('org_id'),
                },
                body: JSON.stringify({
                    org_id: +query.get('org_id'),
                    manager_id: +query.get('manager_id'),
                    lang: query.get('lang'),
                    telegram_id: +query.get('chat_id'),
                    ...values
                })
            }).then(async resp => {
                const jsonData = await resp.json();

                if (resp.status === 200) {
                    setIsSuccess(true);
                    localStorage.setItem('bonusUserId', jsonData.userId);

                } else {
                    console.log(jsonData.message);
                    toast.error(t('server-error'));
                }

            }).catch(error => {
                console.log(error);
                toast.error(t('connection-error'));

            }).finally(() => setIsFetching(false));
        }
    });

    return (
        <React.Fragment>
            <div className={"main-container h-svh"}>
                <Paper elevation={3} className={'p-3 rounded-lg'}>
                    <div className={"flex justify-between align-items-center mb-3"}>
                        <Typography component={"h6"} variant={'h6'}
                                    className={"text-center uppercase poppins-bold-italic"}
                                    color={"textSecondary"} fontSize={18}>
                            {t('registration')}</Typography>
                        <div className={'flex gap-0.5 items-center'}>
                            <LanguageDropdownWithoutName/>
                            <IconButton onClick={changemode} sx={{p: 0}}>
                                {mode === 'light' ?
                                    <DarkMode color="primary" sx={{fontSize: "1.7rem"}}/> :
                                    <LightMode color="warning" sx={{fontSize: "1.7rem"}}/>
                                }
                            </IconButton>
                        </div>
                    </div>
                    <Divider/>
                    {
                        isLoading ?
                            <Box className={"h-32 flex items-center justify-center"}>
                                <CircularProgress color="secondary"/>
                            </Box> :
                            isPending ? <Box className={"mt-3"}>
                                    <Alert icon={<CheckFillIcon fontSize="inherit"/>} severity="success">
                                        {t('registraion-success')}
                                    </Alert>
                                </Box> :
                                <React.Fragment>
                                    <Collapse in={isSuccess}>
                                        <Box className={"mt-3"}>
                                            <Alert icon={<CheckFillIcon fontSize="inherit"/>}
                                                   severity="success">
                                                {t('registraion-success')}
                                            </Alert>
                                        </Box>
                                    </Collapse>
                                    <Collapse in={!isSuccess}>
                                        {
                                            isRejected && <Box className={"mt-2 -mb-4"}>
                                                <AlertControlled
                                                    elevation={0}
                                                    severity={'error'}
                                                    text={t('rejected-info')}
                                                />
                                            </Box>
                                        }
                                        <form className={"mt-3"} onSubmit={e => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <TextFieldOutlinedIcon
                                                icon={<Person/>}
                                                name={"fullName"}
                                                placeholder={t('enter-full-name')}
                                                error={validation.touched.fullName && validation.errors.fullName}
                                                value={validation.values.fullName}
                                                handler={validation.handleChange}/>
                                            <TextFieldOutlinedIcon
                                                icon={<BusinessCenterSharp/>}
                                                name={"speciality"}
                                                placeholder={t('enter-speciality')}
                                                error={validation.touched.speciality && validation.errors.speciality}
                                                value={validation.values.speciality}
                                                handler={validation.handleChange}/>

                                            <TextAreaIcon
                                                icon={<AccountBalanceSharp/>}
                                                name={"workplace"}
                                                placeholder={t('enter-workplace')}
                                                error={validation.touched.workplace && validation.errors.workplace}
                                                value={validation.values.workplace}
                                                handler={validation.handleChange}/>

                                            <PhoneNumberField
                                                name={"phoneNumber"}
                                                error={validation.touched.phoneNumber && validation.errors.phoneNumber}
                                                value={validation.values.phoneNumber}
                                                handler={validation.handleChange}/>

                                            <RowRadioButtonsGroup
                                                name={"gender"}
                                                label={""}
                                                items={[{label: t('male'), value: '1'}, {
                                                    label: t('female'),
                                                    value: '2'
                                                }]}
                                                value={validation.values.gender}
                                                handler={validation.handleChange}/>

                                            <ButtonSubmit
                                                text={t("save")}
                                                sx={{mt: 2}}
                                                size={"medium"}
                                                color={'success'}
                                                isFetching={isFetching}
                                                startIcon={<SaveFillIcon/>}
                                            />
                                        </form>
                                    </Collapse>
                                </React.Fragment>
                    }
                </Paper>
            </div>
        </React.Fragment>
    );
}

export default Index;