import {
    BONUSES_ACTIVE,
    BONUSES_ARCHIVE,
    DOCTOR_DATA,
    PATIENTS,
    SHARES_ACTIVE,
    SHARES_ARCHIVE,
    USER,
    DEMAND_RESPONSES,
    DEMANDS_TO_RESPOND,
    MANAGERS,
    DOCTORS_NO_MANAGER,
    DOCTOR_PATIENTS,
    ADD_DOCTOR_PATIENT,
    DOCTORS_MANAGER_CONNECTED,
    DOCTORS_ADMIN_CONNECTED,
    AVATAR_LOADING, UNPAIND_PATIENTS, PAYMENTS, ADD_PAYMENTS
} from "./actionType";
import payments from "../../pages/admin/payments";

const INIT_STATE = {
    user: {},
    doctorData: {},
    patientsData: {},
    sharesActive: [],
    sharesArchive: [],
    bonusesActive: [],
    bonusesArchive: [],
    demandResponses: [],
    demandsToResponse: [],
    managers: [],
    doctorsNoManager: [],
    patients: [],
    doctorsConnM: [],
    doctorsConnA: [],
    isAvatarLoading: true,
    unpaidPatients: [],
    payments: []
};

const Common = (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER:
            return {
                ...state,
                user: action.payload
            }
        case DOCTOR_DATA:
            return {
                ...state,
                doctorData: action.payload
            }
        case PATIENTS:
            return {
                ...state,
                patientsData: action.payload
            }
        case SHARES_ACTIVE:
            return {
                ...state,
                sharesActive: action.payload
            }
        case SHARES_ARCHIVE:
            return {
                ...state,
                sharesArchive: action.payload
            }
        case BONUSES_ACTIVE:
            return {
                ...state,
                bonusesActive: action.payload
            }
        case BONUSES_ARCHIVE:
            return {
                ...state,
                bonusesArchive: action.payload
            }
        case DEMAND_RESPONSES:
            return {
                ...state,
                demandResponses: action.payload
            }
        case DEMANDS_TO_RESPOND:
            return {
                ...state,
                demandsToResponse: action.payload
            }
        case MANAGERS:
            return {
                ...state,
                managers: action.payload
            }
        case DOCTORS_NO_MANAGER:
            return {
                ...state,
                doctorsNoManager: action.payload
            }
        case DOCTOR_PATIENTS:
            return {
                ...state,
                patients: action.payload
            }
        case ADD_DOCTOR_PATIENT:
            return {
                ...state,
                patients: [...state.patients, action.payload]
            }
        case DOCTORS_MANAGER_CONNECTED:
            return {
                ...state,
                doctorsConnM: action.payload
            }
        case DOCTORS_ADMIN_CONNECTED:
            return {
                ...state,
                doctorsConnA: action.payload
            }
        case AVATAR_LOADING:
            return {
                ...state,
                isAvatarLoading: action.payload
            }
        case UNPAIND_PATIENTS:
            return {
                ...state,
                unpaidPatients: action.payload
            }
        case PAYMENTS:
            return {
                ...state,
                payments: action.payload
            }
        case ADD_PAYMENTS:
            return {
                ...state,
                payments: [...state.payments, action.payload]
            }

        default:
            return state;
    }
}

export default Common;
