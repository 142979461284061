import * as React from 'react';
import {styled} from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {useState} from "react";
import {LoadingButton} from "@mui/lab";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function FileUploadButton({
                                             variant,
                                             color,
                                             fileHandler,
                                             text,
                                             size = "medium"
                                         }) {

    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const handleUpload = (event) => {
        if (event.target.files.length) {
            const file = event.target.files[0];
            if (file.type === 'application/pdf' || file.type.startsWith('image')) {
                setIsLoading(true);
                // console.log("file", file);
                const reader = new FileReader();
                reader.onloadend = () => {
                    fileHandler({
                        base64: reader.result,
                        type: file.type,
                        size: file.size,
                        name: file.name
                    });

                    setIsLoading(false);
                };
                reader.readAsDataURL(file);

            } else {
                toast.warning(t('file-format-warn'));
            }
        }
    };

    return (
        <LoadingButton
            component="label"
            role={undefined}
            variant={variant}
            color={color}
            tabIndex={-1}
            loading={isLoading}
            size={size}
            startIcon={<CloudUploadIcon/>}
        >
            {text}
            <VisuallyHiddenInput
                type="file"
                accept={"image/* , .pdf"}
                onChange={handleUpload}
            />
        </LoadingButton>
    );
}
