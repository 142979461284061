import {DarkMode, LightMode} from "@mui/icons-material";
import {Box, IconButton, useTheme,} from "@mui/material";
import React from "react";
import Sidebar from "./Sidebar";
import LanguageDropdownWithoutName from "../components/common/LanguageDropdownWithoutName";
import {useDispatch, useSelector} from "react-redux";
import {changeMode} from "../store/layout/action";

const Header = () => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const {mode} = useSelector(state => state.LayoutReducer);

    const changemode = () => {
        if (document.querySelector('html').classList.contains('dark')) {
            document.querySelector('html').classList.add('light');
            document.querySelector('html').classList.remove('dark');

        } else {
            document.querySelector('html').classList.add('dark');
            document.querySelector('html').classList.remove('light');
        }
        dispatch(changeMode(mode === 'light' ? 'dark' : 'light'));
        localStorage.setItem('BONUS_MODE', mode === 'light' ? 'dark' : 'light');
    }

    document.querySelector('html').classList.add(mode);

    return (
        <Box
            className='flex justify-between items-center shadow-md px-4 py-0.5 fixed top-0 right-0 left-0'
            sx={{
                backgroundColor: theme.palette.background.default,
            }}
            zIndex={1000}>
            <div className="items-center" id="toggle-menu-btn">
                <Sidebar/>
            </div>
            <div className="flex items-center gap-2">
                <IconButton onClick={changemode}>
                    {mode === 'light' ?
                        <DarkMode color="primary" sx={{fontSize: "1.7rem"}}/> :
                        <LightMode color="warning" sx={{fontSize: "1.7rem"}}/>
                    }
                </IconButton>
                <LanguageDropdownWithoutName/>
            </div>
        </Box>
    );
}
export default Header;