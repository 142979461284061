import React, {useEffect, useState} from 'react';
import {YMaps, Map, Placemark} from 'react-yandex-maps';
import config from "../../../config";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Alert, Box, Paper, Typography} from "@mui/material";
import {ButtonWithSpinner} from "../../../components/buttons/buttons";
import RouteFillIcon from "remixicon-react/RouteFillIcon";
import Swal from "sweetalert2";
import {changePageLoading} from "../../../store/layout/action";
import {useDispatch, useSelector} from "react-redux";

const MapLocation = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {isLoading} = useSelector(state => state.LayoutReducer);

    const [mapInstance, setMapInstance] = useState(null);
    const [ymapsInstance, setYmapsInstance] = useState(null);
    const [routeControl, setRouteControl] = useState(null);

    const [address, setAddress] = useState('');
    const [destination, setDestination] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        fetch(config.urlOrigin + '/get-organization', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem('bonusOrgId')
            }
        }).then(async resp => {
            const data = await resp.json();
            if (resp.status === 200) {
                const locations = JSON.parse(data.location)[0];
                if (locations)
                    setDestination([locations.lat, locations.lon]);

                setAddress(data.address);

            } else {
                console.log(data.message);
                toast.error(t('server-error'));
            }
        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));

        }).finally(() => dispatch(changePageLoading(false)));

    }, []);

    const createRoute = () => {
        if (ymapsInstance && mapInstance) {
            setIsFetching(true);

            if (routeControl) {
                mapInstance.controls.remove(routeControl);
                setRouteControl(null);
            }

            navigator.geolocation.getCurrentPosition((position) => {
                const userLocation = [position.coords.latitude, position.coords.longitude];

                const control = new ymapsInstance.control.RoutePanel({
                    options: {
                        float: 'right',
                    },
                });
                setRouteControl(control);

                mapInstance.controls.add(control);

                control.routePanel.state.set({
                    type: 'masstransit', // 'masstransit' for public transport, 'auto' for driving, etc.
                    fromEnabled: true,
                    from: userLocation, // Set user's current location as the starting point
                    toEnabled: true,
                    to: destination, // Destination coordinates from state
                });

                control.routePanel.options.set({
                    types: {auto: true, masstransit: true, pedestrian: true},
                });

                setIsFetching(false);

            }, (error) => {
                let keyError = '';
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        keyError = 't-locat-notif';
                        break;
                    case error.POSITION_UNAVAILABLE:
                        keyError = 't-locat-notif2';
                        break;
                    case error.TIMEOUT:
                        keyError = 't-locat-notif3';
                        break;
                    default:
                        keyError = 't-unknown-error';
                }

                Swal.fire({
                    icon: 'error',
                    text: t(keyError),
                    showConfirmButton: true,
                    showCloseButton: true
                });

                setIsFetching(false);
            }, {
                maximumAge: 0,
                enableHighAccuracy: true,
                timeout: 5000
            });
        }
    }

    return (
        <React.Fragment>
            {
                !isLoading && (
                    address ?
                        <Paper className={"p-2 mb-2"}>

                            <Typography component={"h6"} variant="h6" fontSize={15} sx={{fontWeight: 500}}>
                                {address}
                            </Typography>
                        </Paper> :
                        <Alert severity={'info'} elevation={1} sx={{mb: 1}}>{t('not-address')}</Alert>
                )
            }
            {
                !isLoading && (
                    destination.length ?
                        <React.Fragment>
                            <Paper className={"p-1 mb-2"} sx={{
                                height: '408px',
                                width: '100%',
                            }}>
                                <YMaps query={{
                                    load: 'package.full',
                                    apikey: "e02bc248-c8ee-41e1-9fe6-f2247769e4b1",
                                    lang: "en_US",
                                    mode: "release"
                                }}>
                                    <Map
                                        defaultState={{
                                            center: destination,
                                            zoom: 10,
                                            controls: ['zoomControl', 'typeSelector', 'fullscreenControl', 'trafficControl']
                                        }}
                                        width="100%"
                                        height="400px"
                                        instanceRef={(ref) => setMapInstance(ref)}
                                        onLoad={(ymaps) => setYmapsInstance(ymaps)}
                                    >
                                        <Placemark geometry={destination}/>
                                    </Map>
                                </YMaps>
                            </Paper>
                            <Paper className={"p-2"}>
                                <Box className={"text-end"}>
                                    <ButtonWithSpinner
                                        variant={"contained"}
                                        color={"primary"}
                                        text={t("create-route")}
                                        elevation={false}
                                        isFetching={isFetching}
                                        handler={createRoute}
                                        startIcon={<RouteFillIcon/>}
                                        size={'small'}
                                    />
                                </Box>
                            </Paper>
                        </React.Fragment> :
                        <Alert severity={'info'} elevation={1} sx={{mb: 1}}>{t('not-location')}</Alert>
                )
            }
        </React.Fragment>
    );
};

export default MapLocation;
