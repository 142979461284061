import React, {useEffect, useState} from "react";
import {Alert, Box, Paper} from "@mui/material";
import ListWithToggle from "../../../components/Lists/ListWithToggle";
import {ButtonWithoutIcon, ButtonWithSpinner} from "../../../components/buttons/buttons";
import {useTranslation} from "react-i18next";
import Save2FillIcon from "remixicon-react/Save2FillIcon";
import config from "../../../config";
import {toast} from "react-toastify";
import {InfoOutlined} from "@mui/icons-material";
import {useSelector} from "react-redux";

const ListOfTodaySent = ({items}) => {
    const {t} = useTranslation();

    const {isLoading} = useSelector(state => state.LayoutReducer);

    const [isFetching, setIsFetching] = useState(false);
    const [managerResps, setManagerResps] = React.useState([]);

    useEffect(() => {
        setManagerResps(items.map(item => ({id: item.id, resp: item.manager_resp})));
    }, [items]);

    const handleManagerRespChanges = (id, val) => {
        setManagerResps(state => {
            let newState = [...state];
            let index = newState.findIndex(item => item.id === id);
            newState[index].resp = val;

            return newState;
        });
    }

    const resetHandler = () => {
        setManagerResps(state => items.map(item => ({id: item.id, resp: item.manager_resp})));
    }

    const saveHadler = () => {
        setIsFetching(true);
        fetch(config.urlOrigin + '/update-patients-manager-reps', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem('bonusOrgId'),
            },
            body: JSON.stringify(managerResps),
        }).then(async response => {
            const data = await response.json();

            if (response.status === 200) {
                toast.success(t('saved'));

            } else {
                console.log(data.message);
                toast.error(t('server-error'));
            }

        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));

        }).finally(() => setIsFetching(false));
    }

    return (
        <Box className={"p-2"}>
            {
                !isLoading && (
                    items.length > 0 ?
                        <React.Fragment>
                            <Paper className={"p-2 mb-2"}>
                                <Box className={"text-end"}>
                                    <ButtonWithoutIcon
                                        sx={{textTransform: "capitalize"}}
                                        size={'small'}
                                        color={"info"}
                                        variant={"contained"}
                                        text={t('reset')}
                                        handler={resetHandler}
                                    />
                                    <ButtonWithSpinner
                                        sx={{marginLeft: "8px", textTransform: "capitalize"}}
                                        size={'small'}
                                        color={"success"}
                                        variant={"contained"}
                                        text={t('save')}
                                        startIcon={<Save2FillIcon/>}
                                        handler={saveHadler}
                                        isFetching={isFetching}
                                    />
                                </Box>
                            </Paper>
                            <Paper className={"p-2"} sx={{backgroundColor: "background.paper"}}>
                                <ListWithToggle items={items} managerResps={managerResps}
                                                handlerChange={handleManagerRespChanges}/>
                            </Paper>
                        </React.Fragment> :
                        <Alert icon={<InfoOutlined fontSize="inherit"/>} elevation={1} severity="info">
                            {t('no-data-found')}
                        </Alert>
                )
            }
        </Box>
    );
}

export default ListOfTodaySent;