import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {ArchiveTwoTone, TaskTwoTone} from "@mui/icons-material";
import TabWithIcon from "../../../components/Tabs/TabWithIcon";
import config from "../../../config";
import {toast} from "react-toastify";
import ActiveShares from "./ActiveShares";
import ArchiveShares from "./ArchiveShares";
import {useDispatch} from "react-redux";
import {changeSharesActive, changeSharesArchive} from "../../../store/common/action";
import {changePageLoading} from "../../../store/layout/action";

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        fetch(config.urlOrigin + '/get-shares', {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                authorization: localStorage.getItem("bonusOrgId"),
            }
        }).then(async resp => {
            const jsonData = await resp.json();
            if (resp.status === 200) {
                const actives = [], archive = [];

                jsonData.data.forEach((item) => {
                    if (item.is_active)
                        actives.push(item);
                    else
                        archive.push(item);
                });

                dispatch(changeSharesActive(actives));
                dispatch(changeSharesArchive(archive));

            } else {
                toast.error(t('server-error'));
                console.log(jsonData.message);
            }

        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));

        }).finally(() => dispatch(changePageLoading(false)));

    }, []);

    return (
        <TabWithIcon
            items={[
                {label: t('actives'), icon: <TaskTwoTone/>, content: <ActiveShares/>},
                {label: t('archive'), icon: <ArchiveTwoTone/>, content: <ArchiveShares/>},
            ]}
        />
    );
}

export default Index;