import React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from "@mui/material/FormControl";
import {FormHelperText} from "@mui/material";

function TextAreaOutlined({
                              label,
                              error,
                              handler,
                              rows = 4,
                              name,
                              value,
                              placeholder
                          }) {
    return (
        <FormControl fullWidth>
            <TextField
                name={name}
                label={label}
                multiline
                rows={rows}
                variant="outlined"
                placeholder={placeholder}
                fullWidth
                error={!!error}
                value={value}
                onChange={handler}
            />
            {error && <FormHelperText error></FormHelperText>}
        </FormControl>
    );
}

export default TextAreaOutlined;
