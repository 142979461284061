import {Alert, Collapse} from "@mui/material";
import React from "react";

export const AlertControlled = ({
                                    text,
                                    elevation = 2,
                                    severity = 'info',
                                    variant = 'standard'
                                }) => {
    const [show, setShow] = React.useState(true);

    return (
        <Collapse in={show}>
            <Alert
                className={"mb-2"}
                severity={severity}
                elevation={elevation}
                onClose={() => setShow(false)}
                variant={variant}
            >
                {text}
            </Alert>
        </Collapse>
    );
}