import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationRU from "./locales/ru.json";
import translationEN from "./locales/en.json";
import translationOZ from "./locales/oz.json";
import translationUZ from "./locales/uz.json";
// the translations
const resources = {
    oz: {
        translation: translationOZ,
    },
    ru: {
        translation: translationRU,
    },
    uz: {
        translation: translationUZ,
    },
    en: {
        translation: translationEN,
    }
};

const language = localStorage.getItem('bonusLang');
if (!language) {
    localStorage.setItem('bonusLang', 'oz');
}

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem('bonusLang'),
        fallbackLng: "en", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
