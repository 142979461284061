import React from "react";
import {Alert, Box} from "@mui/material";
import {SharesCardActive} from "../../../components/card/sharesCards";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import config from "../../../config";
import {toast} from "react-toastify";
import {changeSharesActive, changeSharesArchive} from "../../../store/common/action";

const ActiveShares = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {sharesActive, sharesArchive} = useSelector(state => state.Common);

    const handleArchive = (id) => {
        fetch(config.urlOrigin + '/archive-shares', {
            method: 'PUT',
            headers: {
                "Content-type": "application/json",
                authorization: localStorage.getItem("bonusOrgId"),
            },
            body: JSON.stringify({
                id,
                user_id: +localStorage.getItem('bonusUserId')
            })
        }).then(async resp => {
            const data = await resp.json();
            if (resp.status === 200) {
                const newActiveShares = [...sharesActive];
                const index = newActiveShares.findIndex(share => share.id === id);
                const activeArchived = newActiveShares.splice(index, 1)[0];

                dispatch(changeSharesActive(newActiveShares));

                const newArchiveShares = [...sharesArchive];
                newArchiveShares.push(activeArchived);

                dispatch(changeSharesArchive(newArchiveShares));

                toast.success(t('archived'));

            } else {
                console.log(data.message);
                toast.error(t('server-error'));
            }

        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));
        });
    }

    const handleDelete = (id) => {
        fetch(config.urlOrigin + '/delete-shares', {
            method: 'DELETE',
            headers: {
                "Content-type": "application/json",
                authorization: +localStorage.getItem("bonusOrgId") || 1,
            },
            body: JSON.stringify({
                id
            })
        }).then(async resp => {
            const data = await resp.json();
            if (resp.status === 200) {
                const newActiveShares = [...sharesActive].filter(item => item.id !== id);

                dispatch(changeSharesActive(newActiveShares));
                toast.success(t('deleted'));

            } else {
                console.log(data.message);
                toast.error(t('server-error'));
            }

        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));
        });
    }

    return (
        <Box className="p-2 pb-0">
            {sharesActive?.length > 0 ?
                sharesActive.map((item, index) => (
                    <SharesCardActive item={item}
                                      archiveHandler={handleArchive}
                                      deleteHandler={handleDelete}
                                      key={index}/>
                )) :
                <Alert severity="info" elevation={2}>{t('no-data-found')}</Alert>}
        </Box>
    );
}

export default ActiveShares;