import React, {useEffect, useState} from "react";
import AllManagers from "./AllManagers";
import config from "../../../config";
import {changeDoctorsNoManager, changeManagers} from "../../../store/common/action";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import TabWithIcon from "../../../components/Tabs/TabWithIcon";
import {GroupsTwoTone, PersonAddTwoTone} from "@mui/icons-material";
import AssignDoctors from "./AssignDoctors";
import {changePageLoading} from "../../../store/layout/action";
import {useLocation} from "react-router-dom";

const Index = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            dispatch(changeManagers([]));

            const managersResp = await fetch(config.urlOrigin + '/get-managers-data', {
                method: 'GET',
                headers: {
                    "Content-type": "application/json",
                    authorization: localStorage.getItem("bonusOrgId"),
                }
            });
            const jsonData = await managersResp.json();
            if (managersResp.status === 200) {
                dispatch(changeManagers(jsonData.data));

            } else {
                toast.error(t('server-error'));
                console.log(jsonData.message);
            }

            const usersNoManagersResp = await fetch(config.urlOrigin + '/get-users-no-manager', {
                method: 'GET',
                headers: {
                    "Content-type": "application/json",
                    authorization: localStorage.getItem("bonusOrgId"),
                }
            });
            const jsonData2 = await usersNoManagersResp.json();

            if (usersNoManagersResp.status === 200) {
                dispatch(changeDoctorsNoManager(
                    jsonData2.data.map(item => ({...item, checked: false}))
                ));

            } else {
                toast.error(t('server-error'));
                console.log(jsonData2.message);
            }

            dispatch(changePageLoading(false));

        } catch (e) {
            console.log(e);
            dispatch(changePageLoading(false));
            toast.error(t('connection-error'));
        }
    }

    return (
        <TabWithIcon items={[
            {label: t("managers"), icon: <GroupsTwoTone/>, content: <AllManagers/>},
            {label: t("task"), icon: <PersonAddTwoTone/>, content: <AssignDoctors/>},
        ]}/>
    );
}

export default Index;