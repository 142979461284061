import {useTranslation} from "react-i18next";
import {Box, Collapse, IconButton, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {CancelOutlined, DeleteSharp, DisabledByDefaultSharp, PendingSharp, SendSharp} from "@mui/icons-material";
import React, {useState} from "react";
import TextAreaOutlined from "../Fields/textAreas";
import {useFormik} from "formik";
import * as Yup from "yup";

export const ResponsesCard = ({item, deleteHandler}) => {
    const {t} = useTranslation();

    const [open, setOpen] = useState(true);

    const handleDelete = (id) => {
        deleteHandler(id).then(result => {
            if (result)
                setOpen(false);
        });
    }

    return (
        <Collapse in={open}>
            <Paper className="mb-2">
                <Box className="p-2 pb-0 dark:text-gray-200">
                    <Typography variant="h6" component="h6" fontSize={16}>
                        {item.text}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {moment(item.created_time).format("DD.MM.YYYY HH:mm:ss")}
                    </Typography>
                    {
                        item.status === 2 ?
                            <Box className={"mt-2 border-gray-200 border shadow-inner dark:border-gray-400 p-2"}>
                                <Typography variant="h6" component="h6" fontSize={16}>
                                    <i>{item.resp_text || '-'}</i>
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {item.resp_time ? moment(item.resp_time).format("DD.MM.YYYY HH:mm:ss") : '-'}
                                </Typography>
                            </Box> :
                            item.status === 3 ?
                                <Typography variant="body2" component="p" sx={{marginTop: 2, marginBottom: 0}}>
                                    <DisabledByDefaultSharp color={"error"} className={"me-1"}/>
                                    {t('cancelled')}
                                </Typography> :
                                <Typography variant="body2" component="p" sx={{marginTop: 2, marginBottom: 0}}>
                                    <PendingSharp color={"info"} className={"me-1"}/>
                                    {t('pending')}
                                </Typography>
                    }
                </Box>
                <Box className={"text-end"}>
                    <IconButton color={"error"} onClick={() => {
                        handleDelete(item.id);
                    }}>
                        <DeleteSharp/>
                    </IconButton>
                </Box>
            </Paper>
        </Collapse>
    );
}

export const RequestsToRespondCard = ({item, messageHandler}) => {
    const {t} = useTranslation();

    const [open, setOpen] = useState(true);

    const validation = useFormik({
        initialValues: {
            text: ""
        },
        validationSchema: Yup.object().shape({
            text: Yup.string().required()
        }),
        onSubmit: values => {
            messageHandler(item.id, values.text, 1).then(result => {
                if (result) {
                    setOpen(false);
                }
            });
        }
    });

    return (
        <Collapse in={open}>
            <Paper className="mb-2">
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <Box className="p-2 pb-0 dark:text-gray-200">
                        <Typography variant="h6" component="h6" fontSize={16}>
                            {item.text}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {moment(item.created_time).format("DD.MM.YYYY HH:mm:ss")}
                        </Typography>

                        <Box className={"mt-2.5"}>
                            <TextAreaOutlined
                                handler={validation.handleChange}
                                value={validation.values.text}
                                placeholder={t("enter-response")}
                                rows={2}
                                error={validation.touched.text && validation.errors.text}
                                name={"text"}
                                label={t("response")}
                            />
                        </Box>
                    </Box>
                    <Box className={"text-end pr-1"}>
                        <IconButton color={"error"} onClick={() => {
                            messageHandler(item.id, '', 2).then(result => {
                                if (result) {
                                    setOpen(false);
                                }
                            });
                        }}>
                            <CancelOutlined/>
                        </IconButton>
                        <IconButton color={"success"} type={"submit"}>
                            <SendSharp/>
                        </IconButton>
                    </Box>
                </form>
            </Paper>
        </Collapse>
    );
}