import FormControl from "@mui/material/FormControl";
import {TextField} from "@mui/material";
import Cleave from "cleave.js/react";
import React from 'react';

export const CardNumberField = ({label, value, handler, placeHolder, name, error, size = "small"}) => {
    return (
        <FormControl fullWidth>
            <TextField
                sx={{
                    margin: '20px 0',
                    '& .MuiInputBase-input': {
                        color: 'white',
                    },
                    '& .MuiInputBase-input::placeholder': {
                        color: 'white'
                    },
                    '& .MuiInputLabel-root': !error ? {
                        color: 'white',
                    } : {},
                }}
                color={"warning"}
                placeholder={placeHolder}
                name={name}
                label={label}
                variant="standard"
                value={value}
                onChange={handler}
                error={!!error}
                size={size}
                InputProps={{
                    inputComponent: CleaveInput,
                }}
            />
        </FormControl>
    );
};

const CleaveInput = React.forwardRef((props, ref) => {
    return (
        <Cleave
            {...props}
            htmlRef={ref}
            options={{
                creditCard: true,
            }}
        />
    );
});


export const CardHolderField = ({label, value, handler, placeHolder = '', name, error, size = "small"}) => {

    return (
        <FormControl fullWidth>
            <TextField
                sx={{
                    '& .MuiInputBase-input': {
                        color: 'white',
                    },
                    '& .MuiInputBase-input::placeholder': {
                        color: 'white'
                    },
                    '& .MuiInputLabel-root': !error ? {
                        color: 'white', // Change label color
                    } : {},
                }}
                color={"warning"}
                name={name}
                placeholder={placeHolder}
                label={label}
                variant="standard"
                value={value}
                onChange={handler}
                error={!!error}
                size={size}
            />
        </FormControl>
    );
}

export const CardExpiresField = ({ label, value, handler, placeHolder = '', name, error, size = "small" }) => {
    return (
        <TextField
            sx={{
                width: "60px",
                '& .MuiInputBase-input': {
                    color: 'white'
                },
                '& .MuiInputBase-input::placeholder': {
                    color: 'white'
                },
                '& .MuiInputLabel-root': !error ? {
                    color: 'white',
                } : {},
            }}
            color={"warning"}
            name={name}
            placeholder={placeHolder}
            label={label}
            variant="standard"
            value={value}
            onChange={handler}
            error={!!error}
            size={size}
            InputProps={{
                inputComponent: CleaveFieldExpires,
            }}
        />
    );
};

const CleaveFieldExpires = React.forwardRef(({ inputRef, ...other }, ref) => {
    return (
        <Cleave
            {...other}
            htmlRef={ref}  // Use the forwarded ref here
            options={{
                date: true,
                datePattern: ['m', 'y'],
            }}
        />
    );
});
