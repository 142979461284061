import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export function SelectOutlined({
                                   label,
                                   fullWidth = true,
                                   value,
                                   handler,
                                   error,
                                   items,
                                   size = "medium"
                               }) {

    return (
        <Box sx={{minWidth: 120}}>
            <FormControl fullWidth={fullWidth} size={size}>
                <InputLabel id={"select-label" + label}>{label}</InputLabel>
                <Select
                    labelId={"select-label" + label}
                    value={value}
                    label={label}
                    onChange={handler}
                    error={!!error}
                >
                    {items.map((item, index) => (<MenuItem value={item.value}>{item.label}</MenuItem>))}
                </Select>
            </FormControl>
        </Box>
    );
}
