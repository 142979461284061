import React, {useState} from "react";
import {Box, Paper, Typography} from "@mui/material";
import TextAreaOutlined from "../../../components/Fields/textAreas";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {ButtonReset, ButtonSubmit} from "../../../components/buttons/buttons";
import Save3FillIcon from "remixicon-react/Save3FillIcon";
import {DatePickerOutlined} from "../../../components/Fields/pickers";
import dayjs from "dayjs";
import config from "../../../config";
import {toast} from "react-toastify";
import {ButtonGroupsBasic} from "../../../components/buttons/ButtonGroups";
import {TextFieldOutlinedWithoutHelper} from "../../../components/Fields/textFields";
import {useDispatch, useSelector} from "react-redux";
import {changeSharesActive} from "../../../store/common/action";
import {DeleteSharp} from "@mui/icons-material";
import {AlertControlled} from "../../../components/alerts";

const CreateNewShares = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {sharesActive} = useSelector(state => state.Common);

    const [isFetching, setIsFetching] = useState(false);
    const [showLang, setShowLang] = React.useState('oz');

    const handleChangeLang = (lang) => {
        setShowLang(lang);
    }

    const validation = useFormik({
        initialValues: {
            title_uz: "",
            title_oz: "",
            title_ru: "",
            title_en: "",
            desc_uz: "",
            desc_oz: "",
            desc_ru: "",
            desc_en: "",
            date_start: dayjs(),
            date_end: null
        },
        validationSchema: Yup.object().shape({
            title_uz: Yup.string().required(),
            title_oz: Yup.string().required(),
            title_ru: Yup.string().required(),
            title_en: Yup.string().required(),
            desc_uz: Yup.string().required(),
            desc_oz: Yup.string().required(),
            desc_ru: Yup.string().required(),
            desc_en: Yup.string().required(),
            date_start: Yup.date().required()
        }),
        onSubmit: (values) => {
            setIsFetching(true);

            const {date_start, date_end, ...others} = values;

            fetch(config.urlOrigin + '/create-shares', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("bonusOrgId")
                },
                body: JSON.stringify({
                    ...others,
                    date_start: date_start.format("YYYY-MM-DD"),
                    date_end: date_end ? date_end.format("YYYY-MM-DD") : date_end,
                    user_id: +localStorage.getItem("bonusUserId"),
                })
            }).then(async resp => {
                const data = await resp.json();
                if (resp.status === 200) {
                    toast.success(t('saved'));

                    const newSharesActive = [...sharesActive];
                    newSharesActive.push(data.data);
                    dispatch(changeSharesActive(newSharesActive));

                } else {
                    toast.error(t('server-error'));
                    console.log(data.message);
                }

            }).catch(err => {
                console.error(err)
                toast.error(t('connection-error'));

            }).finally(() => setIsFetching(false));
        }
    })

    return (
        <Box className={"p-2"}>
            <AlertControlled elevation={1} text={t('dont-end-alert')} severity={"info"}/>
            <Paper className="p-2">
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <Box className={"flex justify-between items-end mb-3 text-muted"}>
                        <Typography variant={"body2"} className={"mb-0"} component={"p"}
                                    sx={{lineHeight: 1.1}}>
                            {t('enter-' + showLang)}
                        </Typography>
                        <ButtonGroupsBasic
                            value={showLang}
                            items={[
                                {
                                    value: "oz",
                                    text: "OZ",
                                    color: validation.values.desc_oz && validation.values.title_oz ? 'success' : "error"
                                },
                                {
                                    value: "ru",
                                    text: "RU",
                                    color: validation.values.desc_ru && validation.values.title_ru ? 'success' : "error"
                                },
                                {
                                    value: "uz",
                                    text: "UZ",
                                    color: validation.values.desc_uz && validation.values.title_uz ? 'success' : "error"
                                },
                                {
                                    value: "en",
                                    text: "EN",
                                    color: validation.values.desc_en && validation.values.title_en ? 'success' : "error"
                                },
                            ]}
                            handler={handleChangeLang}/>
                    </Box>
                    <Box hidden={showLang !== 'oz'}>
                        <TextFieldOutlinedWithoutHelper
                            value={validation.values.title_oz}
                            handler={validation.handleChange}
                            error={validation.touched.title_oz && validation.errors.title_oz}
                            label={t('title')}
                            size={"small"}
                            name={"title_oz"}
                            placeholder={t("enter-title")}
                        />
                        <Box className={"mt-3"}>
                            <TextAreaOutlined
                                name={"desc_oz"}
                                label={t("description")}
                                placeholder={t('enter-desc')}
                                rows={3}
                                value={validation.values.desc_oz}
                                handler={validation.handleChange}
                                error={validation.touched.desc_oz && validation.errors.desc_oz}
                            />
                        </Box>
                    </Box>

                    <Box hidden={showLang !== 'ru'}>
                        <TextFieldOutlinedWithoutHelper
                            value={validation.values.title_ru}
                            handler={validation.handleChange}
                            error={validation.touched.title_ru && validation.errors.title_ru}
                            label={t('title')}
                            size={"small"}
                            name={"title_ru"}
                            placeholder={t("enter-title")}
                        />
                        <Box className={"mt-3"}>
                            <TextAreaOutlined
                                name={"desc_ru"}
                                label={t("description")}
                                rows={3}
                                placeholder={t('enter-desc')}
                                value={validation.values.desc_ru}
                                handler={validation.handleChange}
                                error={validation.touched.desc_ru && validation.errors.desc_ru}
                            />
                        </Box>
                    </Box>

                    <Box hidden={showLang !== 'uz'}>
                        <TextFieldOutlinedWithoutHelper
                            value={validation.values.title_uz}
                            handler={validation.handleChange}
                            error={validation.touched.title_uz && validation.errors.title_uz}
                            label={t('title')}
                            size={"small"}
                            name={"title_uz"}
                            placeholder={t("enter-title")}
                        />

                        <Box className={"mt-3"}>
                            <TextAreaOutlined
                                name={"desc_uz"}
                                label={t("description")}
                                rows={3}
                                placeholder={t('enter-desc')}
                                value={validation.values.desc_uz}
                                handler={validation.handleChange}
                                error={validation.touched.desc_uz && validation.errors.desc_uz}
                            />
                        </Box>
                    </Box>

                    <Box hidden={showLang !== 'en'}>
                        <TextFieldOutlinedWithoutHelper
                            value={validation.values.title_en}
                            handler={validation.handleChange}
                            error={validation.touched.title_en && validation.errors.title_en}
                            label={t('title')}
                            size={"small"}
                            name={"title_en"}
                            placeholder={t("enter-title")}
                        />

                        <Box className={"mt-3"}>
                            <TextAreaOutlined
                                name={"desc_en"}
                                label={t("description")}
                                rows={3}
                                placeholder={t('enter-desc')}
                                value={validation.values.desc_en}
                                handler={validation.handleChange}
                                error={validation.touched.desc_en && validation.errors.desc_en}
                            />
                        </Box>
                    </Box>

                    <Box className={"flex flex-col gap-1 mt-1"}>
                        <DatePickerOutlined
                            label={t('date-start')}
                            name={"date_start"}
                            handler={(newValue) => {
                                validation.setFieldValue('date_start', newValue); // Set as Date object
                            }}
                            value={validation.values.date_start}
                        />
                        <DatePickerOutlined
                            label={t('date-end')}
                            name={"date_end"}
                            handler={(newValue) => {
                                validation.setFieldValue('date_end', newValue); // Set as Date object
                            }}
                            value={validation.values.date_end}
                        />

                    </Box>
                    <Box className={"text-end mt-4"}>
                        <ButtonReset
                            color={"primary"}
                            variant={"outlined"}
                            handler={() => {
                                validation.handleReset();
                                setShowLang('oz');
                            }}
                            size={'medium'}
                            text={t('clear')}
                            sx={{
                                mr: 1,
                                // textTransform: 'capitalize',
                            }}
                            startIcon={<DeleteSharp/>}
                        />
                        <ButtonSubmit
                            text={t('save')}
                            startIcon={<Save3FillIcon/>}
                            color={"success"}
                            isFetching={isFetching}
                            fullWidth={false}
                        />
                    </Box>
                </form>
            </Paper>
        </Box>
    );
}

export default CreateNewShares;