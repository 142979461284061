import React from "react";
import {Alert, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import config from "../../../config";
import {toast} from "react-toastify";
import {changeBonusesActive, changeBonusesArchive} from "../../../store/common/action";
import {BonusesCardActive} from "../../../components/card/bonusesCard";

const ActiveBonuses = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {bonusesActive, bonusesArchive} = useSelector(state => state.Common);

    const handleArchive = (id) => {
        fetch(config.urlOrigin + '/archive-bonuses', {
            method: 'PUT',
            headers: {
                "Content-type": "application/json",
                authorization: +localStorage.getItem("bonusOrgId") || 1,
            },
            body: JSON.stringify({
                id,
                user_id: +localStorage.getItem('bonusUserId')
            })
        }).then(async resp => {
            const data = await resp.json();
            if (resp.status === 200) {
                const newActiveBonuses = [...bonusesActive];
                const index = newActiveBonuses.findIndex(share => share.id === id);
                const activeArchived = newActiveBonuses.splice(index, 1)[0];

                dispatch(changeBonusesActive(newActiveBonuses));

                const newArchiveBonuses = [...bonusesArchive];
                newArchiveBonuses.push(activeArchived);

                dispatch(changeBonusesArchive(newArchiveBonuses));

                toast.success(t('archived'));

            } else {
                console.log(data.message);
                toast.error(t('server-error'));
            }

        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));
        });
    }

    const handleDelete = (id) => {
        fetch(config.urlOrigin + '/delete-bonuses', {
            method: 'DELETE',
            headers: {
                "Content-type": "application/json",
                authorization: +localStorage.getItem("bonusOrgId") || 1,
            },
            body: JSON.stringify({
                id
            })
        }).then(async resp => {
            const data = await resp.json();
            if (resp.status === 200) {
                const newActiveBonuses = [...bonusesActive].filter(item => item.id !== id);

                dispatch(changeBonusesActive(newActiveBonuses));
                toast.success(t('deleted'));

            } else {
                console.log(data.message);
                toast.error(t('server-error'));
            }

        }).catch(err => {
            console.log(err);
            toast.error(t('connection-error'));
        });
    }

    return (
        <Box className="p-2 pb-0">
            {bonusesActive?.length > 0 ?
                bonusesActive.map((item, index) => (
                    <BonusesCardActive item={item}
                                       archiveHandler={handleArchive}
                                       deleteHandler={handleDelete}
                                       key={index}/>
                )) :
                <Alert severity="info" elevation={2}>{t('no-data-found')}</Alert>}
        </Box>
    );
}

export default ActiveBonuses;