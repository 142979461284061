import {useTranslation} from "react-i18next";
import List from "@mui/material/List";
import React from "react";
import {Avatar, Checkbox, Divider, ListItemButton} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import defaultUser from "../../../assets/images/user-dummy-img.jpg";
import config from "../../../config";

export default function ListAvatarDoctor({items, handler}) {
    const {t} = useTranslation();

    return (
        <List sx={{width: '100%', height: "100%", overflow: "auto"}} disablePadding>
            {items.map((item, index) => <React.Fragment key={index}>
                <ListItemButton
                    alignItems="center"
                    gap={1}
                    disableGutters={true}
                    disableRipple={true}
                    sx={{paddingBottom: "2px", paddingTop: "2px"}}
                >
                    <ListItemAvatar sx={{minWidth: 53}}>
                        <Avatar
                            alt="Remy Sharp"
                            className={"border-2 border-gray-200 dark:border-gray-600"}
                            sx={{height: 45, width: 45}}
                            src={item['image_path'] ? config.urlOrigin + item['image_path'] : defaultUser}/>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{flexGrow: 1}}
                        primary={item['full_name']}
                        secondary={item.phone}
                        className='text-custom-blue dark:text-gray-300'
                    />
                    <Checkbox
                        checked={item.checked}
                        onChange={e => {
                            handler(e, item.id);
                        }}
                    />
                </ListItemButton>
                <Divider/>
            </React.Fragment>)
            }
        </List>
    );
}

