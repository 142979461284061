import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changePageLoading} from "../../../store/layout/action";
import config from "../../../config";
import {changePayments} from "../../../store/common/action";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Alert, Avatar, Box, IconButton, Paper, Typography} from "@mui/material";
import defaultPdf from "../../../assets/images/pdf.png";
import {formatBytes} from "../../../helpers/common";
import {AccessTimeOutlined, CloudDownloadOutlined} from "@mui/icons-material";
import moment from "moment/moment";
import {ControlledAccordionsInline} from "../../../components/accordions/accordions";
import ListText from "../../../components/Lists/ListText";
import ModalImage from "../../../components/Modals/ModalImage";

const Index = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const {payments} = useSelector(state => state.Common);
    const {isLoading} = useSelector(state => state.LayoutReducer);
    const [showModal, setShowModal] = useState(false);
    const [imagePath, setImagePath] = useState('');

    const [expanded, setExpanded] = React.useState(false);

    const closeModal = () => {
        setShowModal(false);
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleDownload = async (filePath) => {
        try {
            const response = await fetch(config.urlOrigin + filePath, {
                method: 'GET',
                headers: {
                    authorization: localStorage.getItem('bonusOrgId')
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', filePath.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        } catch (error) {
            console.error('Download failed:', error);
            toast.error(t('connection-error'));
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await fetch(config.urlOrigin + '/get-payments?id=' + localStorage.getItem("bonusUserId"), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem('bonusOrgId')
                }
            }
        ).then(async resp => {
            const jsonData = await resp.json();
            if (resp.status === 200) {
                dispatch(changePayments(jsonData.data));

            } else {
                console.log(jsonData.message);
                toast.error(t('server-error'));
            }
        });

        dispatch(changePageLoading(false));
    }

    return (
        <React.Fragment>
            <Box className={"p-2"} sx={{pt: 7.5}}>
                {
                    !isLoading && (
                        payments.length ?
                            payments.map((item, idx) => (
                                <Paper className={"p-2 mb-2"} key={idx}>
                                    <Box className={"mb-2 flex gap-2"}>
                                        <Avatar
                                            src={item.file_type === 1 ? config.urlOrigin + item.file_path : defaultPdf}
                                            alt={""}
                                            variant={"square"}
                                            sx={{
                                                borderRadius: 1
                                            }}
                                            onClick={() => {
                                                if (item.file_type === 1) {
                                                    setShowModal(true);
                                                    setImagePath(config.urlOrigin + item.file_path);
                                                }
                                            }}
                                        />
                                        <Box className={"flex-grow"}>
                                            <Typography fontSize={15}>
                                                {item.file_name}
                                            </Typography>
                                            <Typography fontSize={13} color={"textSecondary"}>
                                                {formatBytes(item.file_size)}
                                            </Typography>
                                        </Box>
                                        <IconButton
                                            size={"small"}
                                            color={"info"}
                                            onClick={() => handleDownload(item.file_path)}
                                        >
                                            <CloudDownloadOutlined/>
                                        </IconButton>
                                    </Box>
                                    <Typography
                                        variant={"body1"}
                                        fontSize={15}
                                        sx={{
                                            mb: 0
                                        }}
                                    >
                                        {item.description}
                                    </Typography>
                                    <Box className={"flex justify-between items-center"}>
                                        <Typography
                                            variant={"body2"}
                                            fontSize={14}
                                            color={'text.secondary'}
                                            sx={{
                                                mb: 0
                                            }}
                                        >
                                            <AccessTimeOutlined
                                                color={"inherit"}
                                                sx={{
                                                    fontSize: 17,
                                                    color: "text.secondary"
                                                }}
                                                className={"mb-0.5 mr-0.5"}
                                            />
                                            {moment(new Date(item.created_time)).format('DD.MM.YYYY, HH:mm:ss')}
                                        </Typography>
                                    </Box>
                                    <Box className={"mt-2"}>
                                        <ControlledAccordionsInline
                                            primaryHeader={t('clients')}
                                            secondaryHeader={'# ' + item.patients.length}
                                            keyUnique={'client' + item.id}
                                            expanded={expanded}
                                            handleChange={handleChange}
                                            content={
                                                <ListText items={item.patients}/>
                                            }
                                        />
                                    </Box>
                                </Paper>
                            )) :
                            <Alert severity={"info"} elevation={1}>
                                {t('no-data-found')}
                            </Alert>
                    )
                }
                <ModalImage
                    isOpen={showModal}
                    handleClose={closeModal}
                    imagePath={imagePath}
                />
            </Box>
        </React.Fragment>
    );

}

export default Index;